"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function DropdownMenuDivider() {
  return _react["default"].createElement("div", {
    className: _DropdownModule["default"].menuDivider
  });
}

var _default = DropdownMenuDivider;
exports["default"] = _default;