"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _TableModule = _interopRequireDefault(require("./Table.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function TableRow(props) {
  var status = props.status,
      Tag = props.tag,
      children = props.children,
      active = props.active,
      size = props.size,
      disabled = props.disabled,
      attributes = _objectWithoutProperties(props, ["status", "tag", "children", "active", "size", "disabled"]);

  var classes = (0, _classnames["default"])(_TableModule["default"].tableRow, active && _TableModule["default"].active, status && _TableModule["default"].status, disabled && _TableModule["default"].disabled, status === "grey" && _TableModule["default"].statusGray, status === "red" && _TableModule["default"].statusRed, status === "yellow" && _TableModule["default"].statusYellow, size === "40px" && _TableModule["default"].height40, size === "48px" && _TableModule["default"].height48, size === "64px" && _TableModule["default"].height64, size === "80px" && _TableModule["default"].height80);
  return _react["default"].createElement(Tag, _extends({
    className: classes
  }, attributes), children);
}

TableRow.propTypes = {
  status: _propTypes["default"].string,
  active: _propTypes["default"].bool,
  tag: _propTypes["default"].oneOfType([_propTypes["default"].string])
};
TableRow.defaultProps = {
  tag: "div"
};
var _default = TableRow;
exports["default"] = _default;