"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _GridModule = _interopRequireDefault(require("./Grid.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function Container(_ref) {
  var children = _ref.children,
      fluid = _ref.fluid,
      gutterLeft = _ref.gutterLeft,
      gutterRight = _ref.gutterRight,
      gutterTop = _ref.gutterTop,
      gutterBottom = _ref.gutterBottom,
      fullHeight = _ref.fullHeight;
  var classes = (0, _classnames["default"])(fluid ? _GridModule["default"].containerFluid : _GridModule["default"].container, fullHeight && _GridModule["default"].h100);
  var inlineStyle = {
    paddingLeft: gutterLeft,
    paddingRight: gutterRight,
    paddingTop: gutterTop,
    paddingBottom: gutterBottom
  };
  return _react["default"].createElement("div", {
    className: classes,
    style: inlineStyle
  }, children);
}

Container.propTypes = {
  children: _propTypes["default"].node,
  fluid: _propTypes["default"].bool,
  gutterLeft: _propTypes["default"].number,
  gutterRight: _propTypes["default"].number,
  gutterTop: _propTypes["default"].number,
  gutterBottom: _propTypes["default"].number
};
var _default = Container;
exports["default"] = _default;