import React from "react";
import styles from "./labelWithTooltip.module.css";
import { Tooltip } from "design-system-react";
import classNames from "classnames";

function labelWithTooltip({ label, fill, noGutter, tooltip }) {
  const containerClasses = classNames(
    styles.container,
    fill && styles.fill,
    noGutter && styles.noGutter
  );
  return (
    <div className={containerClasses}>
      {label && <span className={styles.label}>{label}</span>}
      {tooltip && (
        <span className={styles.tooltip}>
          <svg width="15" height="15" viewBox="0 0 15 15">
            <path
              fillRule="evenodd"
              id="Rounded_Rectangle_849_copy_5"
              data-name="Rounded Rectangle 849 copy 5"
              className="cls-1"
              d="M3188.5,1959a7.5,7.5,0,1,1,7.5-7.5A7.5,7.5,0,0,1,3188.5,1959Zm0-14.01a6.51,6.51,0,1,0,6.51,6.51A6.5,6.5,0,0,0,3188.5,1944.99Zm0,5.01a0.5,0.5,0,0,1,.5.5v4a0.5,0.5,0,0,1-1,0v-4A0.5,0.5,0,0,1,3188.5,1950Zm0-2a0.5,0.5,0,1,1-.5.5A0.5,0.5,0,0,1,3188.5,1948Z"
              transform="translate(-3181 -1944)"
            />
          </svg>
          <Tooltip position="top" size="lg" withWrap dark>
            {tooltip}
          </Tooltip>
        </span>
      )}
    </div>
  );
}

export default labelWithTooltip;
