import React from "react";
import { availability } from "helpers/pageIcons";
import HeaderCard from "components/HeaderCard";
import HeaderIcon from "components/HeaderCard/HeaderIcon";
import HeaderCardContainer from "components/HeaderCard/HeaderCardContainer";
import HeaderCardTitle from "components/HeaderCard/HeaderCardTitle";
import HeaderCardDescription from "components/HeaderCard/HeaderCardDescription";
import { connect } from "react-redux";
import AvailabilityContainer from "./containers/AvailabilityContainer";

function Availability({ brandColor }) {
  return (
    <>
      <HeaderCard>
        <HeaderIcon color={brandColor} icon={availability} />
        <HeaderCardContainer>
          <HeaderCardTitle>Availability</HeaderCardTitle>
          <HeaderCardDescription>
            Set working hours of your company and replies when your support is
            offline
          </HeaderCardDescription>
        </HeaderCardContainer>
      </HeaderCard>
      <AvailabilityContainer />
    </>
  );
}

const mapStateToProps = state => {
  return {
    brandColor: state.setup.current.style.brandColor
  };
};

export default connect(mapStateToProps)(Availability);
