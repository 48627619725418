import React, { useMemo, useRef, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Field, Formik } from "formik";
import { Button, GutterField } from "design-system-react";
import LoginForm from "components/Login/LoginForm";
import LoginFooter from "components/Login/LoginFooter";
import InputField from "components/InputField";
import CheckboxField from "components/CheckboxField";
import { api } from "utils/client";
import { FormError } from "utils/formError";

function PersonalContactFormContainer({
  signUpValues,
  setSignUpValues,
  ...props
}) {
  const form = useRef(null);

  const initialValues = useMemo(() => {
    return {
      firstName: signUpValues.firstName || "",
      lastName: signUpValues.firstName || "",
      phoneNumber: signUpValues.firstName || "",
      agree: true
    };
  }, []);

  const submitStep = useCallback((values, { setSubmitting, setErrors }) => {
    setSubmitting(true);

    api.customers
      .validatePersonal(values)
      .ready.then(() => {
        api.customers
          .signUp(Object.assign(signUpValues, values))
          .ready.then(response => {
            setSignUpValues(response.data.data);
            props.history.push(`/sign-up/4`);
          });
      })
      .catch(e => {
        if (e instanceof FormError) {
          setErrors(e.errors);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, []);

  const prevStep = () => {
    props.history.push("/sign-up/2");
  };

  return (
    <LoginForm>
      <Formik
        ref={form}
        initialValues={initialValues}
        onSubmit={submitStep}
        render={props => {
          return (
            <form>
              <h1>Your personal and contact details</h1>
              <GutterField>
                <Field
                  name="firstName"
                  type="text"
                  label="First name"
                  placeholder="Enter your first name"
                  component={InputField}
                />
              </GutterField>
              <GutterField>
                <Field
                  name="lastName"
                  type="text"
                  label="Last name"
                  placeholder="Enter your last name"
                  component={InputField}
                />
              </GutterField>
              <GutterField>
                <Field
                  name="phoneNumber"
                  type="phone"
                  label="Phone"
                  placeholder="Enter your phone"
                  component={InputField}
                />
              </GutterField>
              <Field
                name="agree"
                size="sm"
                labelText="I agree to the Privacy Police and Terms of service"
                isChecked={props.values.agree}
                component={CheckboxField}
              />
              <LoginFooter>
                <Button outline onClick={prevStep}>
                  Back
                </Button>
                <Button
                  type="submit"
                  disabled={props.isSubmitting || !props.values.agree}
                  onClick={props.handleSubmit}
                >
                  Next
                </Button>
              </LoginFooter>
            </form>
          );
        }}
      />
    </LoginForm>
  );
}

export default withRouter(PersonalContactFormContainer);
