import React from "react";
import MenuListSuccessIcon from "./MenuListSuccessIcon";
import styles from "./MenuListItem.module.css";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

function MenuListItem({ path, name, complete, active, ready, color }) {
  const itemClasses = classNames(
    styles.item,
    complete && styles.complete,
    active && styles.active,
    !ready && styles.blocked
  );

  return (
    <li className={itemClasses}>
      <span
        className={styles.decor}
        style={{ backgroundColor: `${active ? color : "#555"}` }}
      />
      <NavLink exact to={path} />
      {name}
      <MenuListSuccessIcon active={complete} />
    </li>
  );
}

export default MenuListItem;
