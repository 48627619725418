import React from "react";
import { welcomeIcon } from "helpers/pageIcons";
import HeaderCard from "components/HeaderCard";
import HeaderIcon from "components/HeaderCard/HeaderIcon";
import HeaderCardContainer from "components/HeaderCard/HeaderCardContainer";
import HeaderCardTitle from "components/HeaderCard/HeaderCardTitle";
import HeaderCardDescription from "components/HeaderCard/HeaderCardDescription";
import { connect } from "react-redux";
import WelcomeContainer from "pages/Welcome/containers/WelcomeContainer";

function Welcome({ brandColor }) {
  return (
    <div>
      <HeaderCard>
        <HeaderIcon color={brandColor} icon={welcomeIcon} />
        <HeaderCardContainer>
          <HeaderCardTitle>Welcome your customers</HeaderCardTitle>
          <HeaderCardDescription>
            Be friendly to your audience, say something about your support and
            time of reply.
          </HeaderCardDescription>
        </HeaderCardContainer>
      </HeaderCard>
      <WelcomeContainer />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    brandColor: state.setup.current.style.brandColor
  };
};

export default connect(mapStateToProps)(Welcome);
