import axios from "axios";
import { showAlert } from "utils/alertManager";
import store from "store";
import { logout } from "modules/auth";

const COMMON_ERROR_CODE = 10101;
const FIELD_ERROR_CODE = 10001;
const UNAUTHORIZED_ERROR_CODE = 401;

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export function setToken(token) {
  client.defaults.headers.common["Authorization"] = token
    ? "Bearer " + token
    : null;
}

export const api = (endpoint, options, form) => {
  return client(endpoint, options).catch(error => {
    if (error.response.status === UNAUTHORIZED_ERROR_CODE) {
      store.dispatch(logout());
    }

    if (error.response.data.data) {
      const errorCode = error.response.data.data.code;

      if (errorCode === COMMON_ERROR_CODE) {
        const errorMessage = error.response.data.data.message;

        if (errorMessage) {
          showAlert(error.response.data.data.message, "error");
        }
      }

      if (errorCode === FIELD_ERROR_CODE) {
        const fieldErrors = error.response.data.data.info;
        if (fieldErrors && form && form.setErrors) {
          let preparedErrors = {};

          fieldErrors.map(e => {
            preparedErrors[e.source.pointer] = e.title;
          });

          form.setErrors(preparedErrors);
        }
      }
    }

    throw error;
  });
};

let token = localStorage.getItem("token") || sessionStorage.getItem("token");

if (token) {
  setToken(token);
}
