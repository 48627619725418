import React from "react";
import { connect } from "react-redux";
import { introduceIcon } from "helpers/pageIcons";
import HeaderCard from "components/HeaderCard";
import HeaderIcon from "components/HeaderCard/HeaderIcon";
import HeaderCardContainer from "components/HeaderCard/HeaderCardContainer";
import HeaderCardTitle from "components/HeaderCard/HeaderCardTitle";
import HeaderCardDescription from "components/HeaderCard/HeaderCardDescription";
import IntroduceContainer from "./containers/IntroduceContainer";

function Introduce({ brandColor }) {
  return (
    <>
      <HeaderCard>
        <HeaderIcon color={brandColor} icon={introduceIcon} />
        <HeaderCardContainer>
          <HeaderCardTitle>Introduce your Company and Team</HeaderCardTitle>
          <HeaderCardDescription>
            Start with adding a company logo and represent your teammates
          </HeaderCardDescription>
        </HeaderCardContainer>
      </HeaderCard>
      <IntroduceContainer />
    </>
  );
}

const mapStateToProps = state => {
  return {
    brandColor: state.setup.current.style.brandColor
  };
};

export default connect(mapStateToProps)(Introduce);
