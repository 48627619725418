import React, { useEffect } from "react";
import Form from "components/Form";
import { FastField } from "formik";
import { contains } from "utils/hoursWork";
import AvailabilityFields from "../components/AvailabilityFields";
import { GutterField } from "design-system-react";
import { connect } from "react-redux";
import AvailabilityInput from "../components/AvailabilityInput";
import SelectField from "components/SelectField";
import CheckboxField from "components/CheckboxField";
import SwitcherField from "components/SwitcherField";
import CheckboxWrapper from "components/CheckboxWrapper";
import { setAvailability } from "modules/setup";
import useFormSubmission from "hooks/useFormSubmission";
import GroupError from "components/GroupError";

const schedule = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
];

const timezones = [
  { label: "UTC - 12:00", value: -43200 },
  { label: "UTC -11:00", value: -39600 },
  { label: "UTC -10:00", value: -36000 },
  { label: "UTC -09:30", value: -9.5 * 3600 },
  { label: "UTC -09:00", value: -9 * 3600 },
  { label: "UTC -08:00", value: -8 * 3600 },
  { label: "UTC -07:00", value: -7 * 3600 },
  { label: "UTC -06:00", value: -6 * 3600 },
  { label: "UTC -05:00", value: -5 * 3600 },
  { label: "UTC -04:00", value: -14400 },
  { label: "UTC -03:00", value: -10800 },
  { label: "UTC -01:00", value: -1 * 3600 },
  { label: "UTC -00:00", value: -0 * 3600 },
  { label: "UTC +01:00", value: 3600 },
  { label: "UTC +03:00", value: 3 * 3600 },
  { label: "UTC +03:30", value: 3.5 * 3600 },
  { label: "UTC +04:00", value: 4 * 3600 },
  { label: "UTC +04:30", value: 4.5 * 3600 },
  { label: "UTC +05:00", value: 5 * 3600 },
  { label: "UTC +05:30", value: 5.5 * 3600 },
  { label: "UTC +05:45", value: 5.75 * 3600 },
  { label: "UTC +06:00", value: 6 * 3600 },
  { label: "UTC +06:30", value: 6.5 * 3600 },
  { label: "UTC +07:00", value: 7 * 3600 },
  { label: "UTC +08:00", value: 8 * 3600 },
  { label: "UTC +08:45", value: 8.75 * 3600 },
  { label: "UTC +09:00", value: 9 * 3600 },
  { label: "UTC +09:30", value: 9.5 * 3600 },
  { label: "UTC +10:00", value: 10 * 3600 },
  { label: "UTC +10:30", value: 10.5 * 3600 },
  { label: "UTC +11:00", value: 11 * 3600 },
  { label: "UTC +12:00", value: 12 * 3600 },
  { label: "UTC +12:45", value: 12.75 * 3600 },
  { label: "UTC +13:00", value: 13 * 3600 },
  { label: "UTC +14:00", value: 14 * 3600 }
];

function AvailabilityFormContainer({
  values,
  handleSubmit,
  setSubmitDisabled,
  submitDisabled,
  setAvailability,
  errors,
  dirty
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);

  // update setup on form changes
  useEffect(() => {
    setAvailability(values);

    if (!dirty) {
      if (!submitDisabled) {
        setSubmitDisabled(true);
      }
    } else if (submitDisabled) {
      setSubmitDisabled(false);
    }
  }, [values]);

  return (
    <Form onSubmit={handleSubmitForm}>
      <GutterField>
        <FastField
          name="timezone.utc"
          label="Time zone"
          component={SelectField}
          items={timezones}
        />
      </GutterField>
      <GutterField>
        {schedule.map(day => {
          const isDisabled =
            values.schedule[day] && !values.schedule[day].enabled;
          const isChecked =
            values.schedule[day] && values.schedule[day].enabled;
          return (
            <AvailabilityInput
              error={errors && errors[`schedule/${day}`]}
              key={day}
            >
              <FastField
                name={`schedule.${day}.enabled`}
                labelText={day}
                isChecked={isChecked}
                component={CheckboxField}
              />
              <AvailabilityFields disabled={isDisabled}>
                <FastField
                  name={`schedule.${day}.start`}
                  size="sm"
                  component={SelectField}
                  items={contains.map(item => {
                    return { label: item, value: item };
                  })}
                />
                <FastField
                  name={`schedule.${day}.end`}
                  size="sm"
                  component={SelectField}
                  items={contains.map(item => {
                    return { label: item, value: item };
                  })}
                />
              </AvailabilityFields>
            </AvailabilityInput>
          );
        })}
        {errors.schedule && <GroupError>{errors.schedule}</GroupError>}
      </GutterField>
      <CheckboxWrapper label="Show online status" noBorderBottom>
        <FastField name="showOnlineStatus" component={SwitcherField} />
      </CheckboxWrapper>
      <CheckboxWrapper label="Don’t show widget when you are offline">
        <FastField name="hideOffline" component={SwitcherField} />
      </CheckboxWrapper>
    </Form>
  );
}

const mapDispatchToProps = dispatch => ({
  setAvailability: values => dispatch(setAvailability(values))
});

export default connect(
  null,
  mapDispatchToProps
)(AvailabilityFormContainer);
