import React from "react";
import styles from "./HeaderCard.module.css";
import classNames from "classnames";

function HeaderCard({ children }) {
  const classes = classNames(styles.container, "header-card-transition");
  return <div className={classes}>{children}</div>;
}

export default React.memo(HeaderCard);
