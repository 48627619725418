"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _TableModule = _interopRequireDefault(require("./Table.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var classes = (0, _classnames["default"])(_TableModule["default"].tableHeaderColumn, _TableModule["default"].tableRowMenu);

function TableHeaderColumnMenu() {
  return _react["default"].createElement("div", {
    className: classes
  });
}

var _default = TableHeaderColumnMenu;
exports["default"] = _default;