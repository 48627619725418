import React from "react";
import { styleIcon } from "helpers/pageIcons";
import HeaderCard from "components/HeaderCard";
import HeaderIcon from "components/HeaderCard/HeaderIcon";
import HeaderCardContainer from "components/HeaderCard/HeaderCardContainer";
import HeaderCardTitle from "components/HeaderCard/HeaderCardTitle";
import HeaderCardDescription from "components/HeaderCard/HeaderCardDescription";
import { connect } from "react-redux";
import StyleContainer from "./containers/StyleContainer";

function Style({ brandColor }) {
  return (
    <div>
      <HeaderCard>
        <HeaderIcon color={brandColor} icon={styleIcon} />
        <HeaderCardContainer>
          <HeaderCardTitle>Style the widget</HeaderCardTitle>
          <HeaderCardDescription>
            Customize the style your widget to fit your brand guidelines to
            represent your company
          </HeaderCardDescription>
        </HeaderCardContainer>
      </HeaderCard>
      <StyleContainer />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    brandColor: state.setup.current.style.brandColor
  };
};

export default connect(mapStateToProps)(Style);
