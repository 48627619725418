import React from "react";
import { connect } from "react-redux";
import { setUserId } from "modules/auth";
import { api, setToken } from "utils/client";
import { Formik } from "formik";
import LoginForm from "components/Login/LoginForm";
import SignInFormContainer from "./containers/SignInFormContainer";
import { FormError } from "utils/formError";

function SignIn({ setUserId }) {
  const handleSubmit = (values, { setErrors, setSubmitting }) => {
    setSubmitting(true);

    api.customers
      .signIn(values.email, values.password, values.rememberMe)
      .ready.then(response => {
        const id = response.data.data.id;
        const token = response.data.data.tokenSecure;

        if (values.rememberMe) {
          localStorage.setItem("token", token);
          localStorage.setItem("userId", id);
        } else {
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("userId", id);
        }

        setToken(token);
        setUserId(id);
      })
      .catch(e => {
        if (e instanceof FormError) {
          setErrors(e.errors);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <LoginForm>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          email: "",
          password: "",
          rememberMe: true
        }}
        component={SignInFormContainer}
      />
    </LoginForm>
  );
}

const mapDispatchToProps = dispatch => ({
  setUserId: id => dispatch(setUserId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(SignIn);
