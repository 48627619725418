import React from "react";
import Footer from "components/Footer";
import { Button, Preloader } from "design-system-react";

function FooterContainer({
  updateStep,
  disabled,
  loading,
  color,
  onNextClick,
  onBackClick
}) {
  return (
    <Footer>
      {onBackClick && (
        <Button outline disabled={loading} onClick={onBackClick}>
          Back
        </Button>
      )}
      {onNextClick && (
        <Button
          color={color}
          disabled={disabled || loading}
          onClick={onNextClick}
        >
          {loading && <Preloader size="20px" color="#fff" />}
          {!loading && (updateStep ? "Save" : "Next")}
        </Button>
      )}
    </Footer>
  );
}

export default FooterContainer;
