import { withNamespace } from "utils/namespace";
const type = withNamespace("setups");

// action types
export const SET_SETUPS = type("SET_SETUPS");

let initialState = {
  setups: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SETUPS:
      return {
        ...state,
        setups: action.payload.values
      };
    default:
      return state;
  }
}

export function setSetups(values) {
  return {
    type: SET_SETUPS,
    payload: {
      values
    }
  };
}
