import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Field } from "formik";
import { Button, GutterField } from "design-system-react";
import LoginFooter from "components/Login/LoginFooter";
import InputField from "components/InputField";
import SelectField from "components/SelectField";

const employees = [
  "1-9",
  "10-49",
  "50-99",
  "100-249",
  "250-499",
  "500-999",
  "1000",
  "5000"
];
const vertical = [
  "Online services",
  "Banking",
  "FinTech",
  "Retail",
  "Travel",
  "Airlines",
  "Medical",
  "Software",
  "Logisctics & transportation",
  "Gaming",
  "Other"
];

function AboutCompanyFormContainer({
  values,
  prevStep,
  handleSubmit,
  isSubmitting
}) {
  const [isOther, setOther] = useState(false);

  useEffect(() => {
    if (values && values.vertical === "Other") {
      setOther(true);
    } else {
      setOther(false);
    }
  }, [values]);

  return (
    <form>
      <h1>Tell us about your company</h1>
      <GutterField>
        <Field
          name="companyName"
          label="Company name"
          placeholder="Enter your company name"
          component={InputField}
        />
      </GutterField>
      <GutterField>
        <Field
          name="employeesCount"
          component={SelectField}
          label="Number of employees"
          items={employees.map(item => {
            return { label: item, value: item };
          })}
        />
      </GutterField>
      <GutterField>
        <Field
          name="vertical"
          component={SelectField}
          label="Business Vertical"
          items={vertical.map(item => {
            return { label: item, value: item };
          })}
        />
      </GutterField>
      {isOther && (
        <GutterField>
          <Field
            name="other"
            label="Other vertical"
            placeholder="Enter your company vertical"
            component={InputField}
          />
        </GutterField>
      )}
      <LoginFooter>
        <Button outline onClick={prevStep}>
          Back
        </Button>
        <Button type="submit" disabled={isSubmitting} onClick={handleSubmit}>
          Next
        </Button>
      </LoginFooter>
    </form>
  );
}

export default withRouter(AboutCompanyFormContainer);
