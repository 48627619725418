"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _debounce = _interopRequireDefault(require("../../utils/debounce"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function ScrollableList(_ref) {
  var rowCount = _ref.rowCount,
      rowHeight = _ref.rowHeight,
      scrollbarRef = _ref.scrollbarRef,
      children = _ref.children;

  var _useState = (0, _react.useState)(0),
      _useState2 = _slicedToArray(_useState, 2),
      currentPage = _useState2[0],
      setCurrentPage = _useState2[1];

  var _useState3 = (0, _react.useState)(0),
      _useState4 = _slicedToArray(_useState3, 2),
      rowsPerPage = _useState4[0],
      setRowsPerPage = _useState4[1];

  var handleScroll = (0, _react.useCallback)(function (e) {
    var pageHeight = rowHeight * rowsPerPage;
    var page = Math.floor(e.target.scrollTop / pageHeight);

    if (currentPage !== page) {
      setCurrentPage(page);
    }
  }, [currentPage, rowHeight, rowsPerPage]);
  var handleResize = (0, _react.useCallback)(function () {
    if (scrollbarRef.current) {
      var count = Math.round(scrollbarRef.current.clientHeight / rowHeight);
      setRowsPerPage(count);
    }
  }, [scrollbarRef]);
  (0, _react.useEffect)(function () {
    var handler = (0, _debounce["default"])(handleResize);
    window.addEventListener("resize", handler);
    return function () {
      window.removeEventListener("resize", handler);
    };
  }, [handleResize]);
  (0, _react.useEffect)(function () {
    if (scrollbarRef.current) {
      scrollbarRef.current.addEventListener("scroll", handleScroll);
      return function () {
        if (scrollbarRef.current) {
          scrollbarRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [scrollbarRef, handleScroll, handleResize]);
  (0, _react.useEffect)(function () {
    if (scrollbarRef.current) {
      var count = Math.round(scrollbarRef.current.clientHeight / rowHeight);
      setRowsPerPage(count);
    }
  }, [scrollbarRef, rowHeight]);
  var visibleItems = (0, _react.useMemo)(function () {
    var items = [];
    var heightBefore = 0;
    var heightAfter = 0;

    if (rowsPerPage) {
      for (var i = 0; i < rowCount; i++) {
        var itemPage = Math.floor(i / rowsPerPage);

        if (itemPage < currentPage - 1) {
          heightBefore += rowHeight;
        } else if (itemPage > currentPage + 1) {
          heightAfter += rowHeight;
        } else {
          items.push(children(i));
        }
      }

      items.unshift(_react["default"].createElement("div", {
        style: {
          height: heightBefore
        }
      }));
      items.push(_react["default"].createElement("div", {
        style: {
          height: heightAfter
        }
      }));
    }

    return items;
  }, [currentPage, rowsPerPage, children]);
  return _react["default"].createElement(_react["default"].Fragment, null, visibleItems);
}

var _default = ScrollableList;
exports["default"] = _default;