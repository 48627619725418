import { useState, useCallback, useRef } from "react";

let currentInitial;
export default function useStepPage() {
  const form = useRef(null);

  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [redirectToNextStep, setRedirectToNextStep] = useState(false);
  const [redirectToBackStep, setRedirectToBackStep] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [initialSaved, setInitialSaved] = useState(false);

  const handleNextClick = useCallback(() => {
    form.current && form.current.submitForm();
  }, [form]);

  return {
    form,
    loading,
    setLoading,
    validForm,
    setValidForm,
    redirectToNextStep,
    setRedirectToNextStep,
    redirectToBackStep,
    setRedirectToBackStep,
    submitDisabled,
    setSubmitDisabled,
    initialSaved,
    setInitialSaved,
    currentInitial,
    handleNextClick
  };
}
