import React, { useCallback } from "react";
import { Textarea } from "design-system-react";

function TextareaField({
  label,
  disabled,
  field: { name, value },
  form: { errors, setFieldValue }
}) {
  const handleClear = useCallback(() => {
    setFieldValue(name, "");
  }, [value]);

  const handleChange = useCallback(
    e => {
      setFieldValue(name, e.target.value);
    },
    [value]
  );

  let error = errors[name];

  return (
    <Textarea
      name={name}
      label={label}
      value={value}
      validation={error && "error"}
      message={error}
      onClearClick={handleClear}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

export default TextareaField;
