"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _PopupModule = _interopRequireDefault(require("./Popup.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function PopupSubtitle(_ref) {
  var children = _ref.children;
  return _react["default"].createElement("p", {
    className: _PopupModule["default"].subtitle
  }, children);
}

var _default = PopupSubtitle;
exports["default"] = _default;