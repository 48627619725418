import React, { useEffect, useMemo, useCallback } from "react";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { translation_default } from "helpers/translations";
import { api } from "utils/client";
import { setSetup, setWelcome } from "modules/setup";
import useStepPage from "hooks/useStepPage";
import { STEP_AVAILABILITY, STEP_WELCOME } from "helpers/steps";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import WelcomeFormContainer from "pages/Welcome/containers/WelcomeFormContainer";
import FooterContainer from "containers/FooterContainer";

function WelcomeContainer({ setWelcome, setSetup, setup }) {
  const page = useStepPage();
  const defaultLang = setup.introduce.defaultLanguage;

  const initialValues = useMemo(() => {
    return {
      teamMessage:
        setup.welcome.teamMessage ||
        translation_default.teamMessage[defaultLang],
      helloMessage:
        setup.welcome.helloMessage ||
        translation_default.helloMessage[defaultLang],
      offlineMessage:
        setup.welcome.offlineMessage ||
        translation_default.offlineMessage[defaultLang],
      translations: {
        teamMessage: setup.translations.teamMessage,
        helloMessage: setup.translations.helloMessage,
        offlineMessage: setup.translations.offlineMessage
      }
    };
  }, []);

  //Заполнение формы, если они были сохранены
  useEffect(() => {
    document.title = "Welcome your customers";

    if (setup.step > STEP_WELCOME) {
      page.form.current.validateForm();
    }

    page.currentInitial = initialValues;

    return () => {
      setWelcome(page.currentInitial);
    };
  }, []);

  const validateForm = useCallback(
    values => {
      let errors = {};

      if (Object.keys(errors).length === 0) {
        if (!page.validForm) {
          page.setValidForm(true);
        }
      } else if (page.validForm) {
        page.setValidForm(false);
      }

      return errors;
    },
    [page.validForm]
  );

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        api.setups
          .updateWelcome(setup.id, values)
          .ready.then(response => {
            page.currentInitial = response.data.data.welcome;
            setSetup(response.data.data);
            if (response.data.data.step === STEP_AVAILABILITY) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(
                ALERT_SUCCESS,
                "Welcome your customers saved successfully"
              );
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page.loading]
  );

  const handleBackClick = useCallback(() => {
    page.setRedirectToBackStep(true);
  }, []);

  if (page.redirectToBackStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-style"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-availability"} />;
  }

  return (
    <>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        validate={validateForm}
        initialValues={initialValues}
        render={props => (
          <WelcomeFormContainer
            {...props}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <FooterContainer
        updateStep={setup.step > STEP_WELCOME}
        color={setup.style.brandColor}
        disabled={!page.validForm || page.submitDisabled}
        loading={page.loading}
        onBackClick={handleBackClick}
        onNextClick={page.handleNextClick}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.setup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setWelcome: welcome => dispatch(setWelcome(welcome))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeContainer);
