"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function DropdownToggleColor(_ref) {
  var color = _ref.color; // const { label, value } = this.props;
  // const colorItem = colorPalette.filter(item => item.color === value)[0];

  return _react["default"].createElement("div", {
    className: _DropdownModule["default"].toggleColor
  }, _react["default"].createElement("div", {
    className: _DropdownModule["default"].color,
    style: {
      backgroundColor: color
    }
  }), _react["default"].createElement("div", null, color));
}

DropdownToggleColor.propTypes = {
  label: _propTypes["default"].string,
  value: _propTypes["default"].string,
  title: _propTypes["default"].string
};
var _default = DropdownToggleColor;
exports["default"] = _default;