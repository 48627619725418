import React, { useCallback, useEffect, useState } from "react";
import { Input } from "design-system-react";
import LabelWithTooltip from "components/LabelWithTooltip";

function InputNumberField({ form, field, maxValue, label, tooltip, type }) {
  const [isFocus, setFocus] = useState(null);
  const handleClear = useCallback(() => {
    form.setFieldValue(field.name, "");
  }, []);

  useEffect(() => {
    if (Math.sign(field.value) === -1) {
      form.setFieldValue(field.name, 0);
    }

    if (field.value === 0) {
      form.setFieldValue(field.name, 0);
    }

    if (field.value > maxValue) {
      form.setFieldValue(field.name, maxValue);
    }
  }, [field.value]);

  let error = form.errors[field.name];

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  field.onBlur = () => {
    setFocus(false);
  };

  return (
    <>
      <LabelWithTooltip
        label={label}
        fill={!isFocus && (field.value || field.value === Number(0))}
        tooltip={tooltip}
      />
      <Input
        type={type}
        validation={error && "error"}
        message={error}
        onClearClick={handleClear}
        onFocus={handleFocus}
        {...field}
      />
    </>
  );
}

export default InputNumberField;
