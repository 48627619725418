import React from "react";
import { Route, Redirect } from "react-router-dom";
import LoginLayout from "layouts/LoginLayout";
import { connect } from "react-redux";

function LoginLayoutRoute({ component: Component, match, userId, ...props }) {
  if (userId) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...props}
      render={matchProps => (
        <LoginLayout path={props.path}>
          <Component
            activeMenuItem={props.layoutProps.activeMenuItem}
            path={props.path}
            {...matchProps}
          />
        </LoginLayout>
      )}
    />
  );
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId
  };
};

export default connect(mapStateToProps)(LoginLayoutRoute);
