"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reactDom = _interopRequireDefault(require("react-dom"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _PopupModule = _interopRequireDefault(require("./Popup.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var popupRoot = document.getElementById("popup");

if (!popupRoot) {
  popupRoot = document.createElement("div");
  popupRoot.id = "popup";
  document.body.appendChild(popupRoot);
}

function Popup(_ref) {
  var isOpen = _ref.isOpen,
      noPadding = _ref.noPadding,
      noButtonClose = _ref.noButtonClose,
      width = _ref.width,
      children = _ref.children,
      onClose = _ref.onClose;
  var handleOverlayClick = (0, _react.useCallback)(function () {
    if (isOpen) {
      onClose && onClose();
    }
  }, [isOpen, onClose]);
  return _reactDom["default"].createPortal(_react["default"].createElement("div", {
    className: (0, _classnames["default"])(_PopupModule["default"].popupOverlay, isOpen && _PopupModule["default"].open),
    onClick: handleOverlayClick
  }, _react["default"].createElement("div", {
    className: _PopupModule["default"].popupContainer
  }, _react["default"].createElement("div", {
    style: {
      width: width
    },
    className: (0, _classnames["default"])(_PopupModule["default"].popup, noPadding && _PopupModule["default"].noPadding),
    onClick: function onClick(e) {
      e.stopPropagation();
    }
  }, children, !noButtonClose && _react["default"].createElement("div", {
    className: _PopupModule["default"].closeButton,
    onClick: onClose
  }, _react["default"].createElement("i", {
    className: "icon-close"
  }))))), popupRoot);
}

Popup.propTypes = {
  isOpen: _propTypes["default"].bool,
  noPadding: _propTypes["default"].bool,
  noButtonClose: _propTypes["default"].bool,
  children: _propTypes["default"].node,
  onClose: _propTypes["default"].func,
  width: _propTypes["default"].number
};
Popup.defaultProps = {
  isOpen: false,
  noPadding: false,
  noButtonClose: false
};
var _default = Popup;
exports["default"] = _default;