import React from "react";
import PropTypes from "prop-types";
import styles from "./NavPanelItem.module.css";

function NavPanelItem({ children }) {
  return <div className={styles.item}>{children}</div>;
}

NavPanelItem.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavPanelItem;
