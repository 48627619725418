import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import store from "./store";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import "../node_modules/design-system-react/lib/fonts/icons/css/design-system.css";

import AlertContainer from "containers/AlertContainer";

//Layouts
import SetupsLayoutRoute from "routes/SetupsLayoutRoute";
import AppLayoutRoute from "routes/AppLayoutRoute";
import LoginLayoutRoute from "routes/LoginLayoutRoute";

//Pages
import Setups from "pages/Setups";
import Introduce from "pages/Introduce";
import Style from "pages/Style";
import Welcome from "pages/Welcome";
import Availability from "pages/Availability";
import Channels from "pages/Channels";
import Qualify from "pages/Qualify";
import GoLive from "pages/GoLive";
import SignIn from "pages/SignIn";
import ResetPassword from "pages/ResetPassword";
import { SignUp } from "pages/SignUp";

function App() {
  return (
    <Provider store={store}>
      <AlertContainer />
      <Router>
        <Switch>
          <AppLayoutRoute exact path="/" component={Setups} />
          <SetupsLayoutRoute
            exact
            component={Introduce}
            activeMenuItem="introduce"
            path="/setups/:id/update-introduce"
          />
          <SetupsLayoutRoute
            exact
            component={Style}
            activeMenuItem="style"
            path="/setups/:id/update-style"
          />
          <SetupsLayoutRoute
            exact
            component={Welcome}
            activeMenuItem="welcome"
            path="/setups/:id/update-welcome"
          />
          <SetupsLayoutRoute
            exact
            component={Availability}
            activeMenuItem="availability"
            path="/setups/:id/update-availability"
          />
          <SetupsLayoutRoute
            exact
            component={Channels}
            activeMenuItem="channels"
            path="/setups/:id/update-channels"
          />
          <SetupsLayoutRoute
            exact
            component={Qualify}
            activeMenuItem="qualify"
            path="/setups/:id/update-qualify"
          />
          <SetupsLayoutRoute
            exact
            component={GoLive}
            activeMenuItem="live"
            path="/setups/:id/live"
          />
          <LoginLayoutRoute
            exact
            path="/sign-up/:step"
            component={SignUp}
            layoutProps={{
              activeMenuItem: "Sign up"
            }}
          />
          <LoginLayoutRoute
            exact
            path="/reset-password"
            component={ResetPassword}
            layoutProps={{
              activeMenuItem: "Sign In"
            }}
          />
          <LoginLayoutRoute
            exact
            path="/sign-in"
            component={SignIn}
            layoutProps={{
              activeMenuItem: "Sign In"
            }}
          />
          <LoginLayoutRoute exact path="/sign-in" component={SignIn} />
          )}
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
