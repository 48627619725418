import React from "react";
import classNames from "classnames";
import styles from "./style.module.css";

function CheckboxWrapper({ label, noBorderBottom, children }) {
  const classes = classNames(
    styles.wrap,
    noBorderBottom && styles.noBorderBottom
  );
  return (
    <div className={classes}>
      <span className={styles.label}>{label}</span>
      <span className={styles.check}>{children}</span>
    </div>
  );
}

export default CheckboxWrapper;
