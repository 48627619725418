import { withNamespace } from "utils/namespace";
import axios from "axios";
import { api } from "utils/api";

const baseUrl = process.env.REACT_APP_API_URL;

const type = withNamespace("signIn");

export const SIGN_IN_REQUEST = type("SIGN_IN_REQUEST");
export const SIGN_IN_SUCCESS = type("SIGN_IN_SUCCESS");
export const SIGN_IN_FAILURE = type("SIGN_IN_FAILURE");
export const SIGN_OUT = type("SIGN_OUT");

let userId = localStorage.getItem("userId") || sessionStorage.getItem("userId");

let initialState = {
  // isLoggedIn: token && userId,
  userId,
  // token,
  isLoading: false,
  signInError: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        userId: action.userId,
        token: action.token,
        isLoading: false
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        signInError: action.errors,
        isLoading: false,
        isLoggedIn: false
      };
    case SIGN_OUT:
      return {
        ...state,
        isLoggedIn: false
      };
    default:
      return state;
  }
}

export function signIn(params) {
  return dispatch => {
    // dispatch(signInRequest());

    return api("/customers/sign-in", {
      method: "post",
      data: params
    }).then(response => {
      if (JSON.parse(response.config.data).rememberMe) {
        localStorage.setItem("token", response.data.data.tokenSecure);
        localStorage.setItem("userId", response.data.data.id);
      } else {
        sessionStorage.setItem("token", response.data.data.tokenSecure);
        sessionStorage.setItem("userId", response.data.data.id);
      }

      return response;
    });
  };

  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: baseUrl + '/customers/sign-in',
  //       data: params
  //     });
  //
  //     dispatch(
  //       signInSuccess(response.data.data.tokenSecure, response.data.data.id)
  //     );
  //
  //     if (JSON.parse(response.config.data).rememberMe) {
  //       localStorage.setItem("token", response.data.data.tokenSecure);
  //       localStorage.setItem("userId", response.data.data.id);
  //     } else {
  //       sessionStorage.setItem("token", response.data.data.tokenSecure);
  //       sessionStorage.setItem("userId", response.data.data.id);
  //     }
  //
  //     return response.data;
  //   } catch (e) {
  //     dispatch(signInFailure(e.response.data.data));
  //
  //     return e.response.data;
  //   }
  // };
}

export function signInRequest() {
  return {
    type: SIGN_IN_REQUEST
  };
}

export function signInSuccess(token, userId) {
  return {
    type: SIGN_IN_SUCCESS,
    token,
    userId
  };
}

export function signInFailure(errors) {
  return {
    type: SIGN_IN_FAILURE,
    errors
  };
}

export function signOut() {
  // localStorage.clear();
  // sessionStorage.clear();
  return {
    type: SIGN_OUT
  };
}

export function resetPassword(email) {
  return async dispatch => {
    // dispatch(resetPasswordStart());
    try {
      const response = await axios({
        method: "post",
        url: `${baseUrl}/customers/reset-password`,
        data: email
      });
      // dispatch(resetPasswordSuccess());
      return response.data;
    } catch (e) {
      // dispatch(resetPasswordError(e.response.data.data));
      return e.response;
    }
  };
}

export function newPassword(values) {
  return async dispatch => {
    // dispatch(resetPasswordStart());
    try {
      const response = await axios({
        method: "post",
        url: `${baseUrl}/customers/confirm-password`,
        data: values
      });
      // dispatch(resetPasswordSuccess());
      return response.data;
    } catch (e) {
      // dispatch(resetPasswordError(e.response.data.data));
      return e.response.data;
    }
  };
}

export function confirmEmail(tokenConfirm) {
  return async dispatch => {
    // dispatch(resetPasswordStart());
    try {
      const response = await axios({
        method: "post",
        url: `${baseUrl}/customers/confirm-email`,
        data: { confirmationToken: tokenConfirm }
      });
      // dispatch(resetPasswordSuccess());
      return response.data;
    } catch (e) {
      // dispatch(resetPasswordError(e.response.data.data));
      return e.response.data;
    }
  };
}
