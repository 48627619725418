import React from "react";
import styles from "./RadioButtons.module.css";

function RadioButtons({ description, children, vertical }) {
  return (
    <div className={styles.content}>
      <span className={styles.desc}>{description}</span>

      <div className={vertical && styles.vertical}>{children}</div>
    </div>
  );
}

export default RadioButtons;
