import axios from "axios";
import store from "store";
import { FormError } from "./formError";
import { ALERT_ERROR, showAlert } from "./alertManager";
import { logout } from "../modules/auth";
import { CommonError } from "./commonError";

const COMMON_ERROR_CODE = 10101;
const FIELD_ERROR_CODE = 10001;
const UNAUTHORIZED_ERROR_CODE = 401;

export const apiUrl = () => {
  return window.BLINGER_API_URL || process.env.REACT_APP_API_URL;
};

const client = axios.create({
  baseURL: apiUrl()
});

export function setToken(token) {
  if (!token) {
    return;
  }

  client.defaults.headers.common["Authorization"] = token
    ? "Bearer " + token
    : null;
}

export const request = (endpoint, options = {}, withoutAlert = false) => {
  const CancelToken = axios.CancelToken;
  const cancelRequest = CancelToken.source();

  options.cancelToken = cancelRequest.token;

  const ready = client(endpoint, options).catch(error => {
    if (error.response) {
      if (error.response.status === UNAUTHORIZED_ERROR_CODE) {
        store.dispatch(logout());
      }

      if (error.response.data.data) {
        const errorCode = error.response.data.data.code;

        if (errorCode === FIELD_ERROR_CODE) {
          const fieldErrors = error.response.data.data.info;

          if (fieldErrors) {
            let preparedErrors = {};

            fieldErrors.map(e => {
              preparedErrors[e.source.pointer] = e.title;
            });

            throw new FormError(preparedErrors);
          }
        }

        if (errorCode) {
          const errorMessage =
            error.response.data.data.message || "Server error";

          if (!withoutAlert) {
            showAlert(ALERT_ERROR, errorMessage);
          }

          throw new CommonError(errorMessage, error.response.status);
        }
      }
    }

    if (error.message === "Network Error") {
      showAlert(ALERT_ERROR, "Check your network connection status");
    }

    throw error;
  });

  return {
    ready,
    cancelRequest
  };
};

let token = localStorage.getItem("token") || sessionStorage.getItem("token");

if (token) {
  setToken(token);
}

export const api = {
  customers: {
    // one: id => {
    //   return request("/customers/" + id);
    // },
    signIn: (email, password, rememberMe) => {
      return request("/customers/sign-in", {
        method: "post",
        data: {
          email,
          password,
          rememberMe
        }
      });
    },
    signUp: values => {
      return request("/customers/sign-up", {
        method: "post",
        data: values
      });
    },
    resetPassword: email => {
      return request("/customers/reset-password", {
        method: "post",
        data: {
          email
        }
      });
    },
    newPassword: values => {
      return request("/customers/confirm-password", {
        method: "post",
        data: values
      });
    },
    resendEmail: (id, email) => {
      return request("/customers/resend-email", {
        method: "post",
        data: {
          id: id,
          value: email
        }
      });
    },
    confirmEmail: values => {
      return request("/customers/confirm-email", {
        method: "post",
        data: values
      });
    },
    validateCredentials: values => {
      return request("/customers/validate-credentials", {
        method: "post",
        data: values
      });
    },
    validateCompany: values => {
      return request("/customers/validate-company", {
        method: "post",
        data: values
      });
    },
    validatePersonal: values => {
      return request("/customers/validate-personal", {
        method: "post",
        data: values
      });
    }
  },
  setups: {
    setupList: () => {
      return request("/customers/setups");
    },
    updateIntroduce: (id, values, isSimple) => {
      return request(
        "/setups/" +
          id +
          `${isSimple ? "/update-introduce-simple" : "/update-introduce"}`,
        {
          method: "post",
          data: values
        }
      );
    },
    updateStyle: (id, values) => {
      return request("/setups/" + id + "/update-style", {
        method: "post",
        data: values
      });
    },
    updateWelcome: (id, values) => {
      return request("/setups/" + id + "/update-welcome", {
        method: "post",
        data: values
      });
    },
    updateAvailability: (id, values) => {
      return request("/setups/" + id + "/update-availability", {
        method: "post",
        data: values
      });
    },
    updateChannels: (id, values) => {
      return request("/setups/" + id + "/update-channels", {
        method: "post",
        data: values
      });
    },
    // validateChannelLink: (type, values) => {
    //   return request("/setups/validate-channel", {
    //     method: "post",
    //     data: { [type]: values }
    //   });
    // },
    updateQualify: (id, values) => {
      return request("/setups/" + id + "/update-qualify", {
        method: "post",
        data: values
      });
    },
    updateTranslations: (id, values) => {
      return request("/setups/" + id + "/update-translations", {
        method: "post",
        data: values
      });
    },
    publishSetup: id => {
      return request("/setups/" + id + "/publish", {
        method: "post"
      });
    }
  }
};
