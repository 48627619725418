import React from "react";
import styles from "./CollapseButton.module.css";
import classNames from "classnames";
import PropTypes from "prop-types";

function CollapseButton({ toggleMenu, isMenuOpened }) {
  const classes = classNames(styles.stripes, !isMenuOpened && styles.rotate);
  return (
    <div className={styles.burger} onClick={toggleMenu}>
      <div className={classes}>
        <div className={styles.firstStripe} />
        <div className={styles.secondStripe} />
        <div className={styles.thirdStripe} />
      </div>
    </div>
  );
}

CollapseButton.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  isMenuOpened: PropTypes.bool
};

CollapseButton.defaultTypes = {
  isMenuOpened: true
};

export default CollapseButton;
