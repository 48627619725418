"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _TooltipModule = _interopRequireDefault(require("./Tooltip.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function Tooltip(_ref) {
  var size = _ref.size,
      dark = _ref.dark,
      children = _ref.children,
      position = _ref.position,
      offsetX = _ref.offsetX,
      offsetY = _ref.offsetY,
      withWrap = _ref.withWrap,
      withDelay = _ref.withDelay;
  var offsetStyles = (0, _react.useMemo)(function () {
    switch (position) {
      case "left":
        return {
          marginRight: offsetX,
          marginTop: offsetY
        };

      case "right":
        return {
          marginLeft: offsetX,
          marginTop: offsetY
        };

      case "top":
        return {
          marginLeft: offsetX,
          marginBottom: offsetY
        };

      case "bottom":
        return {
          marginLeft: offsetX,
          marginTop: offsetY
        };
    }
  }, [position]);
  return _react["default"].createElement("div", {
    className: (0, _classnames["default"])(_TooltipModule["default"].wrapper)
  }, _react["default"].createElement("div", {
    className: (0, _classnames["default"])(_TooltipModule["default"].tooltip, dark && _TooltipModule["default"].dark, size === "lg" && _TooltipModule["default"].lg, withWrap && _TooltipModule["default"].withWrap, position === "rightBottom" && _TooltipModule["default"].rightBottom, withDelay && _TooltipModule["default"].withDelay, _TooltipModule["default"][position]),
    style: offsetStyles
  }, children));
}

Tooltip.propTypes = {
  dark: _propTypes["default"].bool,
  withWrap: _propTypes["default"].bool,
  children: _propTypes["default"].node,
  size: _propTypes["default"].string,
  position: _propTypes["default"].string,
  offsetX: _propTypes["default"].number,
  offsetY: _propTypes["default"].number
};
Tooltip.defaultProps = {
  dark: false,
  withWrap: false,
  size: "",
  position: "",
  offsetX: "",
  offsetY: ""
};
var _default = Tooltip;
exports["default"] = _default;