"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _TextareaModule = _interopRequireDefault(require("./Textarea.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function Textarea(_ref) {
  var disabled = _ref.disabled,
      resize = _ref.resize,
      label = _ref.label,
      size = _ref.size,
      placeholder = _ref.placeholder,
      message = _ref.message,
      value = _ref.value,
      validation = _ref.validation,
      onChange = _ref.onChange,
      onFocus = _ref.onFocus,
      onBlur = _ref.onBlur,
      onClearClick = _ref.onClearClick,
      attributes = _objectWithoutProperties(_ref, ["disabled", "resize", "label", "size", "placeholder", "message", "value", "validation", "onChange", "onFocus", "onBlur", "onClearClick"]);

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      focused = _useState2[0],
      setFocused = _useState2[1];

  var inputRef = (0, _react.useRef)(null);
  var handleFocus = (0, _react.useCallback)(function (e) {
    setFocused(true);

    if (onFocus) {
      onFocus(e);
    }
  }, [focused, onFocus]);
  var handleBlur = (0, _react.useCallback)(function (e) {
    setFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  }, [focused, onBlur]);
  var handleClearClick = (0, _react.useCallback)(function (e) {
    inputRef.current.focus();

    if (onClearClick) {
      onClearClick(e);
    }
  }, [inputRef, onClearClick]); // const handleRef = useCallback(
  //   node => {
  //     if (innerRef) {
  //       innerRef = node;
  //     }
  //
  //     inputRef = node;
  //   },
  //   [inputRef]
  // );

  var classes = (0, _classnames["default"])(_TextareaModule["default"].inputBlock, disabled && _TextareaModule["default"].disabled, resize && _TextareaModule["default"].resizeTextarea, validation === "success" && _TextareaModule["default"].success || validation === "warning" && _TextareaModule["default"].warning || validation === "error" && _TextareaModule["default"].error, focused && "focused" && _TextareaModule["default"].focused, value && _TextareaModule["default"].nonEmpty);
  return _react["default"].createElement("div", {
    className: classes
  }, label && _react["default"].createElement("label", {
    className: _TextareaModule["default"].label
  }, label), _react["default"].createElement("div", {
    className: _TextareaModule["default"].textareaContainer
  }, _react["default"].createElement("textarea", _extends({
    className: (0, _classnames["default"])(_TextareaModule["default"].textarea),
    placeholder: placeholder,
    ref: inputRef,
    value: value,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: onChange
  }, attributes)), value && _react["default"].createElement("div", {
    className: _TextareaModule["default"].clearButton,
    onClick: handleClearClick
  }, _react["default"].createElement("i", {
    className: "icon-close"
  }))), message && _react["default"].createElement("div", {
    className: _TextareaModule["default"].validationMessage
  }, message));
}

Textarea.propTypes = {
  disabled: _propTypes["default"].bool,
  resize: _propTypes["default"].bool,
  label: _propTypes["default"].string,
  placeholder: _propTypes["default"].string,
  validation: _propTypes["default"].bool,
  message: _propTypes["default"].string,
  size: _propTypes["default"].string,
  value: _propTypes["default"].string.isRequired,
  onChange: _propTypes["default"].func.isRequired,
  onClearClick: _propTypes["default"].func,
  onFocus: _propTypes["default"].func,
  onBlur: _propTypes["default"].func
};
Textarea.defaultProps = {
  disabled: false,
  resize: false,
  label: "",
  placeholder: "",
  validation: "",
  message: "",
  size: ""
};
var _default = Textarea;
exports["default"] = _default;