let handlers = [];
let alertId = 0;
let _component = null;

export const SHOW_ALERT = "SHOW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";

export const ALERT_PROCESS = "process";
export const ALERT_SUCCESS = "success";
export const ALERT_WARNING = "warning";
export const ALERT_ERROR = "error";

export function subscribe(component, event, handler) {
  _component = component;

  if (!handlers[event]) {
    handlers[event] = [];
  }

  handlers[event].push(handler);
}

export function unsubscribe(handler) {
  _component = null;
  handlers = handlers.filter(h => h !== handler);
}

export function notify(event, data) {
  if (!handlers[event] || handlers[event].length < 1) {
    return;
  }

  data["id"] = alertId;

  handlers[event].forEach(handler => {
    handler.call(_component, {
      ...data
    });
  });

  return alertId;
}

export function showAlert(status, message, timeout = 4000) {
  const id = ++alertId;

  notify(SHOW_ALERT, {
    id,
    status,
    message,
    timeout
  });

  return id;
}

export function updateAlert(id, status, message) {
  return notify(UPDATE_ALERT, {
    id,
    status,
    message
  });
}

export function hideAlert(id) {
  return notify(HIDE_ALERT, id);
}
