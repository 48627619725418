import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LoadingLayout from "components/LoadingLayout";

function Setups({ setups }) {
  if (setups.length > 0) {
    const step = setups[0].step || 0;
    const steps = {
      0: "/update-introduce",
      1: "/update-style",
      2: "/update-welcome",
      3: "/update-availability",
      4: "/update-channels",
      5: "/update-qualify",
      6: "/live"
    };

    return <Redirect to={"/setups/" + setups[0].id + steps[step]} />;
  }

  return <LoadingLayout />;
}

const mapStateToProps = state => {
  return {
    setups: state.setups.setups
  };
};

export default connect(mapStateToProps)(Setups);
