"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

var _classnames = _interopRequireDefault(require("classnames"));

var _DropdownContext = require("./DropdownContext");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function DropdownToggleMulti(_ref) {
  var label = _ref.label,
      children = _ref.children,
      hideInput = _ref.hideInput,
      placeholder = _ref.placeholder,
      filter = _ref.filter,
      itemsContainerRef = _ref.itemsContainerRef,
      onCloseClick = _ref.onCloseClick,
      onFilterChange = _ref.onFilterChange;
  var inputEl = (0, _react.useRef)(null);
  var context = (0, _react.useContext)(_DropdownContext.DropdownContext);
  (0, _react.useEffect)(function () {
    if (onFilterChange && context.isOpen) {
      inputEl.current.focus();
    }
  }, [context]);
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement("label", {
    className: _DropdownModule["default"].label
  }, label), _react["default"].createElement("div", {
    className: (0, _classnames["default"])(_DropdownModule["default"].formControl, hideInput && _DropdownModule["default"].hideInput)
  }, _react["default"].createElement("div", {
    ref: itemsContainerRef,
    className: _DropdownModule["default"].multiSelectItems
  }, children), _react["default"].createElement("div", {
    className: _DropdownModule["default"].input
  }, onFilterChange && _react["default"].createElement("input", {
    ref: inputEl,
    className: _DropdownModule["default"].filterInput,
    value: filter,
    onChange: onFilterChange,
    onClick: function onClick(e) {
      context.isOpen && e.stopPropagation();
    }
  }), !context.isOpen && _react["default"].createElement("div", {
    className: _DropdownModule["default"].placeholder
  }, placeholder), _react["default"].createElement("div", {
    className: _DropdownModule["default"].arrowButton,
    onClick: onCloseClick
  }, _react["default"].createElement("i", {
    className: "icon-arrow"
  })))));
}

DropdownToggleMulti.propTypes = {
  label: _propTypes["default"].string,
  hideInput: _propTypes["default"].bool,
  placeholder: _propTypes["default"].string,
  filter: _propTypes["default"].string,
  onFilterChange: _propTypes["default"].string,
  onCloseClick: _propTypes["default"].func
};
DropdownToggleMulti.defaultProps = {
  label: "",
  hideInput: false,
  onCloseClick: null,
  placeholder: "",
  filter: "",
  onFilterChange: null
};
var _default = DropdownToggleMulti;
exports["default"] = _default;