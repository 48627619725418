import React, { useCallback } from "react";

export default function useFormSubmission(submitDisabled, handleSubmit) {
  return useCallback(
    e => {
      if (!submitDisabled) {
        handleSubmit(e);
      } else {
        e.preventDefault();
      }
    },
    [submitDisabled, handleSubmit]
  );
}
