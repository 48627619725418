import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import GetStartedFormContainer from "./containers/GetStartedFormContainer";
import AboutCompanyContainer from "./containers/AboutCompanyContainer";
import PersonalContactFormContainer from "./containers/PersonalContactFormContainer";
import { signIn } from "modules/signIn";
import VerifyMailContainer from "./containers/VerifyMailContainer";

export function SignUp({ ...props }) {
  const [signUpValues, setSignUpValues] = useState({});

  switch (props.match.url) {
    case "/sign-up/1":
      return (
        <GetStartedFormContainer
          setSignUpValues={setSignUpValues}
          signUpValues={signUpValues}
        />
      );
    case "/sign-up/2":
      return (
        <AboutCompanyContainer
          setSignUpValues={setSignUpValues}
          signUpValues={signUpValues}
        />
      );
    case "/sign-up/3":
      return (
        <PersonalContactFormContainer
          setSignUpValues={setSignUpValues}
          signUpValues={signUpValues}
        />
      );
    case "/sign-up/4":
      return (
        <VerifyMailContainer id={signUpValues.id} email={signUpValues.email} />
      );
    default:
      return (
        <GetStartedFormContainer
          setSignUpValues={setSignUpValues}
          signUpValues={signUpValues}
        />
      );
  }
}

const mapDispatchToProps = dispatch => ({
  signIn: (values, form) => dispatch(signIn(values, form))
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(SignUp));
