"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownContext = require("./DropdownContext");

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function Dropdown(_ref) {
  var isOpen = _ref.isOpen,
      toggle = _ref.toggle,
      children = _ref.children,
      disabled = _ref.disabled,
      validation = _ref.validation,
      validationMessage = _ref.validationMessage,
      size = _ref.size,
      multi = _ref.multi,
      nonEmpty = _ref.nonEmpty,
      minWidth = _ref.minWidth;
  var container = (0, _react.useRef)(null);
  (0, _react.useEffect)(function () {
    ["click", "touchstart", "keyup"].forEach(function (event) {
      return document.addEventListener(event, handleDocumentClick, true);
    });
    return function () {
      ["click", "touchstart", "keyup"].forEach(function (event) {
        return document.removeEventListener(event, handleDocumentClick, true);
      });
    };
  }, [container, isOpen]);
  (0, _react.useEffect)(function () {
    if (isOpen) {
      toggle();
    }
  }, [disabled]);
  var handleDocumentClick = (0, _react.useCallback)(function (e) {
    if (container.current.contains(e.target)) {
      return;
    }

    if (isOpen) {
      toggle();
    }
  }, [container, isOpen, toggle]);
  var classes = (0, _classnames["default"])(_DropdownModule["default"].select, multi && _DropdownModule["default"].multiSelect, isOpen && _DropdownModule["default"].open, disabled && _DropdownModule["default"].disabled, validation === "success" && _DropdownModule["default"].success || validation === "warning" && _DropdownModule["default"].warning || validation === "error" && _DropdownModule["default"].error, nonEmpty && _DropdownModule["default"].nonEmpty, size === "sm" && _DropdownModule["default"].sm || size === "lg" && _DropdownModule["default"].lg);
  return _react["default"].createElement(_DropdownContext.DropdownContextProvider, {
    value: {
      isOpen: isOpen,
      toggle: !disabled && toggle
    }
  }, _react["default"].createElement("div", {
    ref: container,
    className: classes,
    style: {
      minWidth: minWidth
    }
  }, children, validationMessage && validation && !disabled && _react["default"].createElement("div", {
    className: _DropdownModule["default"].validationMessage
  }, validationMessage)));
}

Dropdown.propTypes = {
  isOpen: _propTypes["default"].bool.isRequired,
  toggle: _propTypes["default"].func.isRequired,
  nonEmpty: _propTypes["default"].bool,
  disabled: _propTypes["default"].bool,
  size: _propTypes["default"].string,
  validation: _propTypes["default"].string,
  validationMessage: _propTypes["default"].string,
  multi: _propTypes["default"].bool
};
Dropdown.defaultProps = {
  disabled: false,
  size: "",
  validation: "",
  validationMessage: "",
  multi: false
};
var _default = Dropdown;
exports["default"] = _default;