import React from "react";
import { Route } from "react-router-dom";
import SetupsLayout from "layouts/SetupsLayout";
import AppLayout from "layouts/AppLayout";

function SetupsLayoutRoute({ activeMenuItem, component: Component, ...props }) {
  return (
    <Route
      exact
      {...props}
      render={matchProps => {
        return (
          <AppLayout>
            <SetupsLayout
              setupId={matchProps.match.params.id}
              activeMenuItem={activeMenuItem}
              component={Component}
              {...matchProps}
            />
          </AppLayout>
        );
      }}
    />
  );
}

export default SetupsLayoutRoute;
