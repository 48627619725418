import React, { useState, useEffect } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setSetups } from "modules/setups";
import { api } from "utils/client";
import { ALERT_ERROR, showAlert } from "utils/alertManager";
import LoadingLayout from "components/LoadingLayout";

function AppLayout({ component: Component, setSetups, userId, ...props }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.setups
      .setupList()
      .ready.then(response => {
        setSetups(response.data.data);
      })
      .catch(() => {
        showAlert(ALERT_ERROR, "Try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (!userId) {
    return <Redirect to="/sign-in" />;
  }

  if (loading) {
    return <LoadingLayout />;
  }

  return (
    <Route exact path={props.location.pathname}>
      {props.children}
    </Route>
  );
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId
  };
};

const mapDispatchToProps = dispatch => ({
  setSetups: setups => dispatch(setSetups(setups))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppLayout));
