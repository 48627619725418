"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

var _DropdownItem = _interopRequireDefault(require("./DropdownItem"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function DropdownLogoutItem(_ref) {
  var children = _ref.children,
      selected = _ref.selected,
      onClick = _ref.onClick;
  return _react["default"].createElement("div", {
    className: _DropdownModule["default"].isLogout
  }, _react["default"].createElement(_DropdownItem["default"], {
    selected: selected,
    onClick: onClick
  }, children));
}

DropdownLogoutItem.propTypes = {
  children: _propTypes["default"].node.isRequired,
  onClick: _propTypes["default"].func
};
var _default = DropdownLogoutItem;
exports["default"] = _default;