"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function DropdownToggleSelectedMultiItem(_ref) {
  var validation = _ref.validation,
      children = _ref.children,
      onRemove = _ref.onRemove;
  var classes = (0, _classnames["default"])(_DropdownModule["default"].multiSelectItem, validation === "error" && _DropdownModule["default"].error || validation === "warning" && _DropdownModule["default"].warning);
  return _react["default"].createElement("div", {
    className: classes
  }, children, _react["default"].createElement("div", {
    className: _DropdownModule["default"].clearButton,
    onClick: onRemove
  }, _react["default"].createElement("i", {
    className: "icon-close"
  })));
}

DropdownToggleSelectedMultiItem.propTypes = {
  validation: _propTypes["default"].node,
  onRemove: _propTypes["default"].func
};
DropdownToggleSelectedMultiItem.defaultProps = {
  validation: ""
};
var _default = DropdownToggleSelectedMultiItem;
exports["default"] = _default;