"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _SubMenuModule = _interopRequireDefault(require("./SubMenu.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function SubMenuListItem(_ref) {
  var children = _ref.children,
      color = _ref.color,
      text = _ref.text,
      counter = _ref.counter,
      counterAll = _ref.counterAll,
      Tag = _ref.tag,
      active = _ref.active,
      attributes = _objectWithoutProperties(_ref, ["children", "color", "text", "counter", "counterAll", "tag", "active"]);

  var classes = (0, _classnames["default"])(_SubMenuModule["default"].listItem, active && _SubMenuModule["default"].active);
  var count = counter > 999 ? "1k+" : counter;
  return _react["default"].createElement(Tag, _extends({
    className: classes
  }, attributes), _react["default"].createElement("div", {
    className: _SubMenuModule["default"].listItemCol
  }, _react["default"].createElement("div", {
    className: _SubMenuModule["default"].listItemText
  }, text), counter > 0 ? _react["default"].createElement("div", {
    className: _SubMenuModule["default"].counterWrapper
  }, _react["default"].createElement("span", {
    className: _SubMenuModule["default"].tooltip,
    style: {
      background: color
    }
  }, children, _react["default"].createElement("span", {
    className: _SubMenuModule["default"].tooltipText
  }, count))) : null), _react["default"].createElement("div", {
    className: _SubMenuModule["default"].counterAll
  }, counterAll));
}

SubMenuListItem.propTypes = {
  counter: _propTypes["default"].number,
  text: _propTypes["default"].node.isRequired,
  color: _propTypes["default"].string,
  counterAll: _propTypes["default"].number.isRequired,
  active: _propTypes["default"].bool
};
SubMenuListItem.defaultProps = {
  tag: "div"
};
var _default = SubMenuListItem;
exports["default"] = _default;