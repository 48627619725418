import React, { useEffect, useCallback, useState } from "react";
import RadioButtons from "components/Radio/RadioButtons";
import { RadioButton } from "design-system-react";

function RadioButtonsContainer({
  form,
  defaultValue,
  nameForm,
  fistLabel,
  secondLabel,
  placeholder,
  vertical,
  disabled
}) {
  const [isChecked, setIsChecked] = useState(defaultValue);

  const handleChange = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (isChecked) {
      form.setFieldValue(nameForm, true);
    } else {
      form.setFieldValue(nameForm, false);
    }
  }, [isChecked]);

  return (
    <RadioButtons vertical={vertical} description={placeholder}>
      <RadioButton
        isChecked={isChecked}
        onChange={handleChange}
        labelText={fistLabel || "Yes"}
        size="sm"
        disabled={disabled}
      />
      <RadioButton
        isChecked={!isChecked}
        onChange={handleChange}
        labelText={secondLabel || "no"}
        size="sm"
        disabled={disabled}
      />
    </RadioButtons>
  );
}

export default RadioButtonsContainer;
