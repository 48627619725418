import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { api } from "../../utils/client";
import { setUserId } from "../../modules/auth";
import { Formik } from "formik";
import ResetPasswordForm from "./components/ResetPasswordForm";
import ResetPasswordFormContainer from "./containers/ResetPasswordFormContainer";
import { ALERT_SUCCESS, showAlert } from "../../utils/alertManager";
import ResendBlock from "./components/ResendBlock";

function ResetPassword({ match }) {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const resetPassword = useCallback(email => {
    setLoading(true);
    const request = api.customers.resetPassword(email);
    request.ready.finally(() => {
      setLoading(false);
    });

    return request.ready;
  }, []);

  const resend = useCallback(() => {
    if (!loading) {
      resetPassword(email).then(() => {
        showAlert(ALERT_SUCCESS, "Password reset link has been sent to email");
      });
    }
  }, [loading, email]);

  const handleSubmit = useCallback(
    (values, { setErrors, setSubmitting }) => {
      resetPassword(values.email)
        .then(() => {
          setEmail(values.email);
          setEmailSent(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [resetPassword]
  );

  if (emailSent) {
    return (
      <ResendBlock>
        <h1>
          An e-mail with password reset link has been sent to email {email}
        </h1>
        <span>Didn't receive the e-mail?</span>
        <p onClick={resend}>Resend</p>
      </ResendBlock>
    );
  }

  return (
    <ResetPasswordForm>
      <Formik
        initialValues={{
          name: "",
          password: "",
          token: match.params.token
        }}
        onSubmit={handleSubmit}
        component={ResetPasswordFormContainer}
      />
    </ResetPasswordForm>
  );
}

const mapDispatchToProps = dispatch => ({
  setUserId: id => dispatch(setUserId(id))
});

export default connect(
  null,
  mapDispatchToProps
)(ResetPassword);
