import React from "react";
import { qualifyIcon } from "helpers/pageIcons";
import { connect } from "react-redux";
import HeaderCard from "components/HeaderCard";
import HeaderIcon from "components/HeaderCard/HeaderIcon";
import HeaderCardContainer from "components/HeaderCard/HeaderCardContainer";
import HeaderCardTitle from "components/HeaderCard/HeaderCardTitle";
import HeaderCardDescription from "components/HeaderCard/HeaderCardDescription";
import QualifyContainer from "./containers/QualifyContainer";

function Qualify({ brandColor }) {
  return (
    <div>
      <HeaderCard>
        <HeaderIcon color={brandColor} icon={qualifyIcon} />
        <HeaderCardContainer>
          <HeaderCardTitle>Qualify leads</HeaderCardTitle>
          <HeaderCardDescription>
            Customize the style your widget to fit your brand guidelines to
            represent your company
          </HeaderCardDescription>
        </HeaderCardContainer>
      </HeaderCard>
      <QualifyContainer />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    brandColor: state.setup.current.style.brandColor
  };
};

export default connect(mapStateToProps)(Qualify);
