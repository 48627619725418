import React from "react";
import classNames from "classnames";
import styles from "./AvailabilityInputs.module.css";

function AvailabilityFields({ children, disabled }) {
  const classes = classNames(styles.fields, disabled && styles.disabled);
  return <div className={classes}>{children}</div>;
}

export default AvailabilityFields;
