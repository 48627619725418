"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function DropdownItem(_ref) {
  var Tag = _ref.tag,
      children = _ref.children,
      selected = _ref.selected,
      onClick = _ref.onClick,
      arrow = _ref.arrow,
      attributes = _objectWithoutProperties(_ref, ["tag", "children", "selected", "onClick", "arrow"]);

  var classes = (0, _classnames["default"])(_DropdownModule["default"].dropdownItem);
  return _react["default"].createElement("li", {
    className: classes,
    onClick: onClick
  }, _react["default"].createElement(Tag, attributes, children, arrow && _react["default"].createElement("div", {
    className: _DropdownModule["default"].arrow
  }, _react["default"].createElement("i", {
    className: "ico-arrow-right"
  }))), selected && _react["default"].createElement("div", {
    className: _DropdownModule["default"].itemCheck
  }, _react["default"].createElement("i", {
    className: "icon-check"
  })));
}

DropdownItem.propTypes = {
  children: _propTypes["default"].node.isRequired,
  onClick: _propTypes["default"].func,
  arrow: _propTypes["default"].bool
};
DropdownItem.defaultProps = {
  tag: "a",
  arrow: false
};
var _default = DropdownItem;
exports["default"] = _default;