import React from "react";
import "./main.css";
import Widget from "components/Widget";
import { connect } from "react-redux";
import { translation_default } from "helpers/translations";
import WidgetChannelsContainer from "./WidgetChannelsContainer";
import WidgetQualifyContainer from "./WidgetQualifyContainer";
import WidgetWelcomeContainer from "./WidgetWelcomeContainer";
import WidgetIntroduceContainer from "containers/WidgetContainer/WidgetIntroduceContainer";

function WidgetContainer({ style, activeMenuItem, defaultLanguage }) {
  // Styles
  let brandColor = style.brandColor || "#d4d5d9";
  let highlightColor = style.highlightColor || "#ffffff";

  function translate(string) {
    return translation_default[string][defaultLanguage];
  }

  return (
    <Widget>
      <div
        id="blinger-channels"
        className="channels"
        style={{ borderColor: brandColor }}
      >
        <div
          className={`channels-wrapper ${activeMenuItem ===
            "/setups/:id/update-qualify" && "channels-wrapper-chat-show"}`}
        >
          <div className="channels-wrapper-scroll">
            <WidgetIntroduceContainer />

            <div className="channels-wrapper-container">
              <div className="channels-wrapper-chat">
                <WidgetWelcomeContainer />
              </div>

              <div
                className={
                  "channels-wrapper-chat-button complete " + highlightColor
                }
              >
                <div className="channels-wrapper-chat-button-title">
                  {translate("openChat") || "Open chat"}
                </div>
                <div
                  className="channels-wrapper-chat-button-arrow"
                  style={{ background: brandColor }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="11"
                    viewBox="0 0 6 11"
                  >
                    <path
                      fill={highlightColor}
                      id="arrow"
                      data-name="arrow"
                      className="arrow"
                      d="M573.113,658.787l4.712,4.7-4.712,4.7a0.476,0.476,0,0,0,.674.673l5-4.991a0.281,0.281,0,0,0,.062-0.026,0.529,0.529,0,0,0,0-.708,0.335,0.335,0,0,0-.062-0.025l-5-4.99A0.476,0.476,0,0,0,573.113,658.787Z"
                      transform="translate(-573 -658)"
                    />
                  </svg>
                </div>
                <div
                  className="bg"
                  style={{
                    background: brandColor
                  }}
                />
              </div>

              <WidgetChannelsContainer defaultLanguage={defaultLanguage} />
            </div>
          </div>

          <div className={`powered-by`}>
            <span>Powered by</span>
            <a
              style={{ color: brandColor }}
              href="https://blinger.io/"
              target="_blank"
            >
              <svg
                className="copy-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="12"
                viewBox="0 0 7 12"
              >
                <path
                  fill={brandColor}
                  d="M453,1064h-3v-6l-4,6h3v6.01Z"
                  transform="translate(-446 -1058)"
                />
              </svg>
              blinger.io
            </a>
          </div>
        </div>
        <div
          className={`chat-wrapper ${activeMenuItem ===
            "/setups/:id/update-qualify" && "chat-wrapper-active"}`}
        >
          <WidgetIntroduceContainer isOpen />
          <WidgetQualifyContainer defaultLanguage={defaultLanguage} />

          <div className="chat-message-input">
            <textarea
              placeholder={translate("writeMessage") || "Write a message..."}
            />
            <div className="chat-message-input-buttons">
              <div className="chat-message-input-buttons-emogy">
                <img
                  className="emogy-chat-icon"
                  src="/images/emogy.svg"
                  alt=""
                />
              </div>

              <div className="chat-message-input-buttons-add">
                <img className="add-chat-icon" src="/images/add.svg" alt="" />
              </div>

              <div className="chat-message-send-button">
                <img className="send-chat-icon" src="/images/send.svg" alt="" />
              </div>

              <div className="chat-message-emogy">
                <div className="chat-message-emogy-search">
                  <img
                    className="search-chat-icon"
                    src="/images/search.svg"
                    alt=""
                  />
                  <input type="text" />
                </div>
                <div className="chat-message-emogy-block">
                  <img
                    className="emogies-chat-icon"
                    src="/images/emogy.gif"
                    alt=""
                  />
                  <img
                    className="emogies-chat-icon"
                    src="/images/emogy.gif"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Widget>
  );
}

const mapStateToProps = state => {
  return {
    defaultLanguage: state.setup.current.introduce.defaultLanguage || "en",
    style: state.setup.current.style
  };
};

export default connect(mapStateToProps)(WidgetContainer);
