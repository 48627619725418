import React from "react";
import classNames from "classnames";
import styles from "./Form.module.css";

function Form({ onSubmit, isDrag, children }) {
  const classes = classNames(styles.form, isDrag && styles.isDrag);
  return (
    <form className={classes} onSubmit={onSubmit}>
      {children}
    </form>
  );
}

export default Form;
