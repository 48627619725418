"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = debounce;

function debounce(callback) {
  var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 300;
  var timer;
  return function (event) {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(callback, delay, event);
  };
}