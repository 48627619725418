import React, { useMemo, useCallback, useEffect } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setAvailability, setSetup } from "modules/setup";
import useStepPage from "hooks/useStepPage";
import { STEP_AVAILABILITY, STEP_CHANNELS } from "helpers/steps";
import { api } from "utils/client";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import FooterContainer from "containers/FooterContainer";
import AvailabilityFormContainer from "./AvailabilityFormContainer";

const defaultSchedule = {
  monday: { end: "", start: "", enabled: false },
  tuesday: { end: "", start: "", enabled: false },
  wednesday: { end: "", start: "", enabled: false },
  thursday: { end: "", start: "", enabled: false },
  friday: { end: "", start: "", enabled: false },
  saturday: { end: "", start: "", enabled: false },
  sunday: { end: "", start: "", enabled: false }
};

let date = new Date();
let timezone = date.getTimezoneOffset() * -60;

function AvailabilityContainer({ setup, setSetup, setAvailability }) {
  const page = useStepPage();

  const initialValues = useMemo(() => {
    return {
      schedule: setup.availability.schedule || defaultSchedule,
      showOnlineStatus:
        String(setup.availability.showOnlineStatus) === "false" ? false : true,
      hideOffline: setup.availability.hideOffline || false,
      timezone: setup.availability.timezone || { utc: timezone }
    };
  }, []);

  useEffect(() => {
    document.title = "Availability";

    if (setup.step > STEP_AVAILABILITY) {
      page.form.current.validateForm();
    }

    page.currentInitial = initialValues;

    return () => {
      // reset form
      setAvailability(page.currentInitial);
    };
  }, []);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        api.setups
          .updateAvailability(setup.id, values)
          .ready.then(response => {
            setSetup(response.data.data);
            page.currentInitial = response.data.data.availability;
            if (response.data.data.step === STEP_CHANNELS) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(
                ALERT_SUCCESS,
                "Availability settings saved successfully"
              );
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page.loading]
  );

  const handleBackClick = useCallback(() => {
    page.setRedirectToBackStep(true);
  }, []);

  if (page.redirectToBackStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-welcome"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-channels"} />;
  }

  return (
    <>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={props => (
          <AvailabilityFormContainer
            {...props}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <FooterContainer
        updateStep={setup.step > STEP_AVAILABILITY}
        color={setup.style.brandColor}
        disabled={page.submitDisabled}
        loading={page.loading}
        onBackClick={handleBackClick}
        onNextClick={page.handleNextClick}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.setup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setAvailability: values => dispatch(setAvailability(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailabilityContainer);
