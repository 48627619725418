import React, { useMemo, useEffect, useCallback } from "react";
import { Formik } from "formik/dist/index";
import { Redirect } from "react-router-dom";
import { translation_default } from "helpers/translations";
import { connect } from "react-redux";
import { api } from "utils/client";
import { setSetup, setQualify } from "modules/setup";
import useStepPage from "hooks/useStepPage";
import { STEP_LIVE, STEP_QUALIFY } from "helpers/steps";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import FooterContainer from "containers/FooterContainer";
import QualifyFormContainer from "./QualifyFormContainer";

function QualifyContainer({ setQualify, setSetup, setup }) {
  const page = useStepPage();
  const initialValues = useMemo(() => {
    return {
      autoMessage:
        setup.qualify.autoMessage ||
        translation_default.teamMessage[setup.introduce.defaultLanguage],
      translations: {
        autoMessage: setup.translations.autoMessage
      },
      details: setup.qualify.details || {
        name: true,
        email: true,
        phone: false
      },
      rule: setup.qualify.rule || "firstMessage"
    };
  }, []);

  useEffect(() => {
    document.title = "Qualify leads";
    page.currentInitial = initialValues;

    return () => {
      // reset form
      setQualify(page.currentInitial);
    };
  }, []);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);
        api.setups
          .updateQualify(setup.id, values)
          .ready.then(response => {
            setSetup(response.data.data);
            page.currentInitial = response.data.data.qualify;
            if (response.data.data.step === STEP_LIVE) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(
                ALERT_SUCCESS,
                "Availability settings saved successfully"
              );
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page.loading]
  );

  const renderForm = useCallback(
    props => {
      return (
        <QualifyFormContainer
          {...props}
          submitDisabled={page.submitDisabled}
          setSubmitDisabled={page.setSubmitDisabled}
        />
      );
    },
    [page.submitDisabled]
  );

  const handleBackClick = useCallback(() => {
    page.setRedirectToBackStep(true);
  }, []);

  if (page.redirectToBackStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-channels"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/setups/" + setup.id + "/live"} />;
  }

  return (
    <>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={renderForm}
      />
      <FooterContainer
        updateStep={setup.step > STEP_QUALIFY}
        color={setup.style.brandColor}
        disabled={page.submitDisabled}
        loading={page.loading}
        onBackClick={handleBackClick}
        onNextClick={page.handleNextClick}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.setup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setQualify: welcome => dispatch(setQualify(welcome))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualifyContainer);
