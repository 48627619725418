import React from "react";
import { channelsIcon } from "helpers/pageIcons";
import HeaderCard from "components/HeaderCard";
import HeaderIcon from "components/HeaderCard/HeaderIcon";
import HeaderCardContainer from "components/HeaderCard/HeaderCardContainer";
import HeaderCardTitle from "components/HeaderCard/HeaderCardTitle";
import HeaderCardDescription from "components/HeaderCard/HeaderCardDescription";
import { connect } from "react-redux";
import { setChannels, setSetup } from "modules/setup";
import ChannelsContainer from "./containers/ChannelsContainer";

function Channels({ brandColor }) {
  return (
    <div>
      <HeaderCard>
        <HeaderIcon color={brandColor} icon={channelsIcon} />
        <HeaderCardContainer>
          <HeaderCardTitle>Set up channels</HeaderCardTitle>
          <HeaderCardDescription>
            Add messaging channels via which your customers can contact you to
            the widget
          </HeaderCardDescription>
        </HeaderCardContainer>
      </HeaderCard>
      <ChannelsContainer />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    brandColor: state.setup.current.style.brandColor
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setChannels: values => dispatch(setChannels(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Channels);
