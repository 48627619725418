"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = [// { color: "#37409f" },
{
  color: "#ce295a"
}, {
  color: "#b9007c"
}, {
  color: "#b629aa"
}, {
  color: "#9b08ae"
}, {
  color: "#7715af"
}, {
  color: "#632ba1"
}, {
  color: "#4c2db4"
}, {
  color: "#2d46ba"
}, //
{
  color: "#4cb813"
}, {
  color: "#00b24e"
}, {
  color: "#00ac74"
}, {
  color: "#00aba4"
}, {
  color: "#00afc0"
}, {
  color: "#00a0d2"
}, {
  color: "#0082d0"
}, {
  color: "#1068c1"
}, //
{
  color: "#78b300"
}, {
  color: "#9eb700"
}, {
  color: "#aab200"
}, {
  color: "#bba300"
}, {
  color: "#d19b00"
}, {
  color: "#e37e00"
}, {
  color: "#e75800"
}, {
  color: "#d74637"
}];
exports["default"] = _default;