"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

var _DropdownContext = require("./DropdownContext");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function DropdownToggleIcon(_ref) {
  var children = _ref.children,
      positionRelative = _ref.positionRelative;
  var context = (0, _react.useContext)(_DropdownContext.DropdownContext);
  var classes = (0, _classnames["default"])(_DropdownModule["default"].iconContainer, positionRelative && _DropdownModule["default"].positionRelative);
  return _react["default"].createElement("div", {
    onClick: context.toggle,
    className: classes
  }, _react["default"].createElement("span", {
    className: _DropdownModule["default"].icon
  }, children));
}

DropdownToggleIcon.protoType = {
  icon: _propTypes["default"].bool,
  children: _propTypes["default"].node
};
var _default = DropdownToggleIcon;
exports["default"] = _default;