import React from "react";
import PropTypes from "prop-types";
import styles from "./NavPanelContent.module.css";

function NavPanelContent({ children }) {
  return <div className={styles.content}>{children}</div>;
}

NavPanelContent.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavPanelContent;
