"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ScrollbarModule = _interopRequireDefault(require("./Scrollbar.module.scss"));

var _reactScrollbarsCustom = require("react-scrollbars-custom");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var wrapperProps = {
  style: {
    right: "0",
    bottom: "0"
  }
};

function Scrollbar(_ref) {
  var width = _ref.width,
      height = _ref.height,
      thumbSize = _ref.thumbSize,
      children = _ref.children,
      innerRef = _ref.innerRef,
      props = _objectWithoutProperties(_ref, ["width", "height", "thumbSize", "children", "innerRef"]);

  var _useState = (0, _react.useState)(false),
      _useState2 = _slicedToArray(_useState, 2),
      inUse = _useState2[0],
      setInUse = _useState2[1];

  var hideTimeoutHandler = (0, _react.useMemo)(function () {
    return null;
  }, []);
  var trackXProps = (0, _react.useMemo)(function () {
    return {
      className: _ScrollbarModule["default"].track,
      style: {
        width: "calc(100% - 4px)",
        height: thumbSize,
        left: "2px",
        bottom: "2px",
        background: "none",
        opacity: inUse ? 1 : 0,
        transition: "opacity .1s"
      }
    };
  }, [inUse, thumbSize]);
  var trackYProps = (0, _react.useMemo)(function () {
    return {
      className: _ScrollbarModule["default"].track,
      style: {
        width: thumbSize,
        height: "calc(100% - 4px)",
        top: "2px",
        right: "2px",
        background: "none",
        opacity: inUse ? 1 : 0,
        transition: "opacity .1s"
      }
    };
  }, [inUse, thumbSize]);
  var handleScrollStart = (0, _react.useCallback)(function () {
    if (hideTimeoutHandler) {
      clearTimeout(hideTimeoutHandler);
    }

    if (!inUse) {
      setInUse(true);
    }
  }, [inUse]);
  var handleScrollStop = (0, _react.useCallback)(function () {
    if (hideTimeoutHandler) {
      clearTimeout(hideTimeoutHandler);
    }

    hideTimeoutHandler = setTimeout(function () {
      if (inUse) {
        setInUse(false);
      }
    }, 600);
  }, [inUse]);
  var scrollerProps = (0, _react.useMemo)(function () {
    if (innerRef) {
      return {
        elementRef: innerRef
      };
    }

    return {};
  }, [innerRef]);
  var scrollbarStyles = (0, _react.useMemo)(function () {
    return {
      width: width,
      height: height
    };
  }, [width, height]);
  return _react["default"].createElement(_reactScrollbarsCustom.Scrollbar, _extends({
    style: scrollbarStyles,
    wrapperProps: wrapperProps,
    trackXProps: trackXProps,
    trackYProps: trackYProps,
    scrollerProps: scrollerProps,
    onScrollStart: handleScrollStart,
    onScrollStop: handleScrollStop
  }, props), children);
}

Scrollbar.propTypes = {
  width: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number]).isRequired,
  height: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number]).isRequired,
  thumbSize: _propTypes["default"].number
};
Scrollbar.defaultProps = {
  width: "100%",
  height: "100%",
  thumbSize: 7
};
var _default = Scrollbar;
exports["default"] = _default;