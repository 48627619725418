"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

var _DropdownContext = require("./DropdownContext");

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function DropdownToggleContent(_ref) {
  var children = _ref.children,
      isOpen = _ref.isOpen;
  var context = (0, _react.useContext)(_DropdownContext.DropdownContext);
  var classes = (0, _classnames["default"])(_DropdownModule["default"].toggleContentContainer, isOpen && _DropdownModule["default"].isOpenContent);
  return _react["default"].createElement("div", {
    onClick: context.toggle,
    className: classes
  }, _react["default"].createElement("div", {
    className: _DropdownModule["default"].toggleContent
  }, children, _react["default"].createElement("div", {
    className: _DropdownModule["default"].contentIcon
  }, _react["default"].createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "11",
    height: "6",
    viewBox: "0 0 11 6"
  }, _react["default"].createElement("path", {
    id: "Rounded_Rectangle_1075_copy_60",
    "data-name": "Rounded Rectangle 1075 copy 60",
    className: "cls-1",
    d: "M1886.83,787.845l-4.96,4.979c-0.01.012-.01,0.029-0.02,0.04a0.5,0.5,0,0,1-.37.144,0.489,0.489,0,0,1-.36-0.144c-0.01-.011-0.01-0.028-0.02-0.04l-4.96-4.979a0.5,0.5,0,0,1,.7-0.709l4.64,4.653,4.65-4.653A0.5,0.5,0,0,1,1886.83,787.845Z",
    transform: "translate(-1876 -787)"
  })))));
}

var _default = DropdownToggleContent;
exports["default"] = _default;