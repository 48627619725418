import React, { useMemo, useCallback, useEffect } from "react";
import { Formik } from "formik";
import { setSetup, setStyle } from "modules/setup";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import StyleFormContainer from "pages/Style/containers/StyleFormContainer";
import FooterContainer from "containers/FooterContainer";
import { STEP_STYLE, STEP_WELCOME } from "helpers/steps";
import useStepPage from "hooks/useStepPage";
import { api } from "utils/client";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";

function StyleContainer({ setup, setSetup, setStyle }) {
  const page = useStepPage();

  const initialValues = useMemo(() => {
    return {
      brandColor: setup.style.brandColor || "#d4d5d9",
      highlightColor: setup.style.highlightColor || "White",
      position: setup.style.position || "Left",
      sideSpacing: setup.style.sideSpacing || 0,
      bottomSpacing: setup.style.bottomSpacing || 0,
      includeCopyrights: true
    };
  }, []);

  useEffect(() => {
    document.title = "Style the widget";

    if (setup.step > STEP_STYLE) {
      page.form.current.validateForm();
    }

    page.currentInitial = initialValues;

    return () => {
      setStyle(page.currentInitial);
    };
  }, []);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        api.setups
          .updateStyle(setup.id, values)
          .ready.then(response => {
            page.currentInitial = response.data.data.style;
            setSetup(response.data.data);
            if (response.data.data.step === STEP_WELCOME) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(ALERT_SUCCESS, "Style your company saved successfully");
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page.loading]
  );

  const handleBackClick = useCallback(() => {
    page.setRedirectToBackStep(true);
  }, []);

  if (page.redirectToBackStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-introduce"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-welcome"} />;
  }

  return (
    <>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={props => (
          <StyleFormContainer
            {...props}
            submitDisabled={page.submitDisabled}
            setSubmitDisabled={page.setSubmitDisabled}
          />
        )}
      />
      <FooterContainer
        updateStep={setup.step > STEP_STYLE}
        color={setup.style.brandColor}
        disabled={page.submitDisabled}
        loading={page.loading}
        onBackClick={handleBackClick}
        onNextClick={page.handleNextClick}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.setup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setStyle: values => dispatch(setStyle(values)),
  setSetup: setup => dispatch(setSetup(setup))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyleContainer);
