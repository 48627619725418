import React, { useCallback } from "react";
import { Switcher } from "design-system-react";

function SwitcherField({ form, field, ...props }) {
  const handleChange = useCallback(() => {
    form.setFieldValue(field.name, !field.value);
  }, [field.value]);

  return (
    <Switcher isChecked={field.value} onChange={handleChange} {...props} />
  );
}

export default SwitcherField;
