import React from "react";
import { connect } from "react-redux";
import Menu from "components/Menu";
import MenuTitle from "components/Menu/MenuTitle";
import MenuCopyright from "components/Menu/MenuCopyright";
import MenuList from "components/Menu/MenuList";
import MenuListItem from "components/Menu/MenuListItem";

function MenuContainer({ active, step, setupId, color }) {
  const items = [
    {
      name: "Introduce company",
      ready: true,
      complete: step >= 1,
      path: "/setups/" + setupId + "/update-introduce",
      active: "introduce" === active
    },
    {
      name: "Style the widget",
      ready: step >= 1,
      complete: step >= 2,
      path: "/setups/" + setupId + "/update-style",
      active: "style" === active
    },
    {
      name: "Welcome your customers",
      ready: step >= 2,
      complete: step >= 3,
      path: "/setups/" + setupId + "/update-welcome",
      active: "welcome" === active
    },
    {
      name: "Availability",
      ready: step >= 3,
      complete: step >= 4,
      path: "/setups/" + setupId + "/update-availability",
      active: "availability" === active
    },
    {
      name: "Setup channels",
      ready: step >= 4,
      complete: step >= 5,
      path: "/setups/" + setupId + "/update-channels",
      active: "channels" === active
    },
    {
      name: "Qualify leads",
      ready: step >= 5,
      complete: step >= 6,
      path: "/setups/" + setupId + "/update-qualify",
      active: "qualify" === active
    },
    {
      name: "Go live",
      ready: step >= 6,
      complete: step >= 6,
      path: "/setups/" + setupId + "/live",
      active: "live" === active
    }
  ];

  return (
    <Menu>
      <MenuTitle>Setup</MenuTitle>
      <MenuList>
        {items.map(item => (
          <MenuListItem
            key={item.name}
            color={color}
            name={item.name}
            ready={item.ready}
            complete={item.complete}
            path={item.path}
            active={item.active}
          />
        ))}
      </MenuList>
      <MenuCopyright>© Blinger limited 2019</MenuCopyright>
    </Menu>
  );
}

const mapStateToProps = state => {
  return {
    step: state.setup.current.step,
    setupId: state.setup.current.id,
    color: state.setup.current.style.brandColor
  };
};

export default connect(mapStateToProps)(React.memo(MenuContainer));
