import React from "react";
import styles from "./HeaderIcon.module.css";

function HeaderIcon({ icon, color }) {
  return (
    <div className={styles.container}>
      <svg className={styles.icon}>
        <path fill={color} d={icon.d} transform={icon.transform} />
      </svg>
    </div>
  );
}

export default HeaderIcon;
