import React from "react";
import { Link } from "react-router-dom";
import styles from "./Login.module.css";
import NavPanel from "components/NavPanel";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanelItem from "components/NavPanel/NavPanelItem";

function Login({ children, path }) {
  return (
    <div className={styles.container}>
      <div className="header">
        <NavPanel>
          <NavPanelContent>
            <NavPanelItem>
              <img src="/images/Blinger_Logo.svg" alt="" />
            </NavPanelItem>
          </NavPanelContent>
          <NavPanelContent>
            <NavPanelItem>
              <div className={styles.header}>
                {path === "/sign-up/:step" ? (
                  <p className={styles.link}>
                    <span>Already have an account?</span>
                    <Link to="/sign-in">Sign in</Link>
                  </p>
                ) : (
                  <p className={styles.link}>
                    <span>Don’t have an account?</span>
                    <Link to="/sign-up/1">Sign up</Link>
                  </p>
                )}
              </div>
            </NavPanelItem>
          </NavPanelContent>
        </NavPanel>
      </div>
      {children}
      <div className={styles.footer}>
        <span className={styles.copy}>© Blinger limited 2019</span>
        <div className={styles.footerLinks}>
          <a
            href="https://blinger.io/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of service
          </a>
          <a
            href="https://blinger.io/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
          <a
            href="https://blinger.io/cookie-consent"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie policy
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
