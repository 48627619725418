import React, { useState, useCallback } from "react";
import ResendBlock from "pages/ResetPassword/components/ResendBlock";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { Redirect } from "react-router-dom";
import { api } from "utils/client";

function VerifyMailContainer({ id, email }) {
  const [disabled, setDisabled] = useState(false);

  const resend = useCallback(() => {
    api.customers.resendEmail(id, email).ready.then(() => {
      setDisabled(true);
      showAlert(
        ALERT_SUCCESS,
        "Verification link has been resent to your email"
      );
    });
  }, [email]);

  if (!email) {
    return <Redirect to="/" />;
  }

  return (
    <ResendBlock>
      <h1>An e-mail with verification link has been sent to {email}</h1>
      {!disabled && (
        <>
          <span>Didn't receive the e-mail?</span>
          <p onClick={resend}>Resend</p>
        </>
      )}
    </ResendBlock>
  );
}

export default VerifyMailContainer;
