import React, { useState, useCallback } from "react";
import NavPanel from "../../components/NavPanel";
import CollapseButton from "../../components/NavPanel/CollapseButton";
import { connect } from "react-redux";
import {
  Avatar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggleIcon
} from "design-system-react";
import { logout } from "modules/auth";
import NavPanelContent from "components/NavPanel/NavPanelContent";
import NavPanelItem from "components/NavPanel/NavPanelItem";
import { extractFirstLetters } from "utils/string";

function NavPanelContainer({ isMenuOpened, toggleMenu, logout, name }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <NavPanel>
      <CollapseButton toggleMenu={toggleMenu} isMenuOpened={isMenuOpened} />
      <NavPanelContent>
        <NavPanelItem>
          <Dropdown isOpen={isOpen} toggle={toggle}>
            <DropdownToggleIcon positionRelative>
              <Avatar size="size40" text={extractFirstLetters(name, 2)} />
            </DropdownToggleIcon>
            <DropdownMenu gutterBottom={20} gutterRight={10} direction={"left"}>
              <DropdownItem onClick={logout}>Log out</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavPanelItem>
      </NavPanelContent>
    </NavPanel>
  );
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(
  null,
  mapDispatchToProps
)(NavPanelContainer);
