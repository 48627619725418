import React, { useMemo, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { setChannels, setSetup } from "modules/setup";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import FooterContainer from "containers/FooterContainer";
import { STEP_CHANNELS, STEP_QUALIFY } from "helpers/steps";
import ChannelsFormContainer from "./ChannelsFormContainer";
import useStepPage from "hooks/useStepPage";
import { api } from "utils/client";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";

const channelsDefault = {
  channels: [
    { link: "", type: "facebook", enabled: false },
    { link: "", type: "whatsapp", enabled: false },
    { link: "", type: "telegrambot", enabled: false },
    { link: "", type: "viber", enabled: false },
    { link: "", type: "vk", enabled: false },
    { link: "", type: "email", enabled: false },
    { link: "", type: "instagram", enabled: false },
    { link: "", type: "kik", enabled: false },
    { link: "", type: "line", enabled: false },
    { link: "", type: "ok", enabled: false },
    { link: "", type: "skype", enabled: false },
    { link: "", type: "twitter", enabled: false },
    { link: "", type: "wechat", enabled: false }
  ]
};

function ChannelsPageContainer({
  setup,
  setSetup,
  setChannels,
  handleSetModal,
  modalValue
}) {
  const page = useStepPage();
  const initialValues = useMemo(() => {
    return (setup.channels.channels && setup.channels) || channelsDefault;
  }, []);

  useEffect(() => {
    document.title = "Setup channels";

    page.currentInitial = initialValues;

    return () => {
      // reset form
      setChannels(page.currentInitial);
    };
  }, []);

  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        api.setups
          .updateChannels(setup.id, values)
          .ready.then(response => {
            page.currentInitial = response.data.data.channels;
            setSetup(response.data.data);
            if (response.data.data.step === STEP_QUALIFY) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(ALERT_SUCCESS, "Channels settings saved successfully");
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page.loading]
  );

  const renderForm = useCallback(
    props => {
      return (
        <ChannelsFormContainer
          {...props}
          modalValue={modalValue}
          handleSetModal={handleSetModal}
          submitDisabled={page.submitDisabled}
          setSubmitDisabled={page.setSubmitDisabled}
        />
      );
    },
    [page.submitDisabled, modalValue]
  );

  const handleBackClick = useCallback(() => {
    page.setRedirectToBackStep(true);
  }, []);

  if (page.redirectToBackStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-availability"} />;
  }

  if (page.redirectToNextStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-qualify"} />;
  }

  return (
    <>
      <Formik
        ref={page.form}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={renderForm}
      />
      <FooterContainer
        updateStep={setup.step > STEP_CHANNELS}
        color={setup.style.brandColor}
        disabled={page.submitDisabled}
        loading={page.loading}
        onBackClick={handleBackClick}
        onNextClick={page.handleNextClick}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.setup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setChannels: values => dispatch(setChannels(values))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChannelsPageContainer);
