import React from "react";
import classNames from "classnames";
import styles from "./RadiobuttonFields.module.css";

function RadiobuttonFields({ horizontal, description, children }) {
  return (
    <div className={styles.content}>
      <span className={styles.desc}>{description}</span>
      <div
        className={classNames(styles.buttons, horizontal && styles.horizontal)}
      >
        {children}
      </div>
    </div>
  );
}

export default RadiobuttonFields;
