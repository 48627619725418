import React, { useCallback, useEffect, useState } from "react";
import {
  Popup,
  PopupBody,
  PopupHeader,
  PopupFooter,
  Button,
  Input
} from "design-system-react";
import { defineChannel } from "utils/defineChannel";
import usePrevious from "hooks/usePrevious";
import { emailMask, urlMask } from "helpers/validate";

function validate(value, type) {
  if (type === "email") {
    return emailMask.test(value);
  } else {
    return urlMask.test(value);
  }
}

function ChannelModalContainer({ close, modalParams, setValues }) {
  const [value, setValue] = useState("");
  const [isDirty, setDirty] = useState(false);
  const [error, setError] = useState(null);
  let prevValue = usePrevious(modalParams.link);

  useEffect(() => {
    if (modalParams.link) {
      setValue(modalParams.link);
    } else {
      setValue("");
    }
    if (!modalParams.isOpen) {
      setError(null);
    }
  }, [modalParams]);

  const handleSubmit = () => {
    if (validate(value, modalParams.type)) {
      setValues({ type: modalParams.type, link: value, enabled: true });
      setError(null);
      setValue("");
      close();
    } else {
      setError("This field is not valid.");
    }
    setDirty(false);
  };

  const handleClear = useCallback(() => {
    setValue("");
    setDirty(false);
    setError(null);
  }, []);

  const handleChange = e => {
    setValue(e.target.value);
    if (e.target.value.match(/^[ ]+$/)) {
      setValue("");
    }
    if (e.target.value.trim() !== prevValue) {
      setError(null);
      setDirty(true);
    } else {
      setDirty(false);
    }
  };

  const handleKeyPress = e => {
    if (e.key === "Enter" && isDirty) {
      handleSubmit();
      e.preventDefault();
    }
  };

  return (
    <Popup isOpen={modalParams.isOpen} onClose={close}>
      <PopupHeader>Add {defineChannel(modalParams.type).name}</PopupHeader>
      <PopupBody>
        <Input
          validation={error && "error"}
          message={error}
          type="text"
          onKeyPress={handleKeyPress}
          label={(modalParams.type === "email" && "Email") || "link"}
          onClearClick={handleClear}
          onChange={handleChange}
          value={value}
        />
      </PopupBody>
      <PopupFooter>
        <Button onClick={handleSubmit} disabled={!isDirty}>
          Save
        </Button>
        <Button outline={true} onClick={close}>
          Cancel
        </Button>
      </PopupFooter>
    </Popup>
  );
}

export default ChannelModalContainer;
