"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _MenuModule = _interopRequireDefault(require("./Menu.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function MenuListItem(_ref) {
  var children = _ref.children,
      Tag = _ref.tag,
      counter = _ref.counter,
      active = _ref.active,
      attributes = _objectWithoutProperties(_ref, ["children", "tag", "counter", "active"]);

  var counterLength = counter && counter.toLocaleString().length;
  var classes = (0, _classnames["default"])(_MenuModule["default"].menuListItem, active && _MenuModule["default"].active);
  var counterClasses = (0, _classnames["default"])(_MenuModule["default"].counter, counterLength === 2 && _MenuModule["default"].doubleCount, counterLength >= 3 && _MenuModule["default"].tripleCount);
  var count = counter > 999 ? "1k+" : counter;
  return _react["default"].createElement(Tag, _extends({
    className: classes
  }, attributes), children, counter > 0 ? _react["default"].createElement("span", {
    className: counterClasses
  }, count) : null);
}

MenuListItem.propTypes = {
  children: _propTypes["default"].node.isRequired,
  counter: _propTypes["default"].number,
  active: _propTypes["default"].bool,
  counterColor: _propTypes["default"].string
};
var _default = MenuListItem;
exports["default"] = _default;