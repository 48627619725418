import React from "react";
import styles from "./CustomSide.module.css";
import classNames from "classnames";

function CustomSide({ children }) {
  const classes = classNames(styles.container);

  return <div className={classes}>{children}</div>;
}

export default CustomSide;
