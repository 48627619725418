"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _EmojiModule = _interopRequireDefault(require("./Emoji.module.scss"));

var _classnames = _interopRequireDefault(require("classnames"));

var _emojis = _interopRequireDefault(require("./emojis"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function Emoji(_ref) {
  var isOpen = _ref.isOpen,
      onEmojiClick = _ref.onEmojiClick;
  var classes = (0, _classnames["default"])(_EmojiModule["default"].emojiContainer, isOpen && _EmojiModule["default"].open);
  return _react["default"].createElement("div", {
    className: classes,
    onClick: function onClick(e) {
      e.stopPropagation();
    }
  }, _emojis["default"].map(function (emoji) {
    return _react["default"].createElement("div", {
      className: _EmojiModule["default"].emoji,
      onClick: function onClick() {
        onEmojiClick(emoji["char"]);
      }
    }, _react["default"].createElement("span", {
      className: _EmojiModule["default"].emojiIcon
    }, emoji["char"]));
  }));
}

Emoji.propTypes = {
  isOpen: _propTypes["default"].bool
};
Emoji.defaultProps = {
  isOpen: false
};
var _default = Emoji;
exports["default"] = _default;