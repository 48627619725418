import React from "react";
import styles from "./ToggableLayout.module.css";
import classNames from "classnames";

function ToggableLayoutContent({ children, isMenuOpened }) {
  const classes = classNames(styles.content, isMenuOpened && styles.subMenu);
  return <div className={classes}>{children}</div>;
}

export default ToggableLayoutContent;
