import React, { useEffect } from "react";
import Form from "components/Form";
import { FastField } from "formik";
import { GutterField, Row, Col } from "design-system-react";
import SelectColorField from "components/SelectColorField";
import { setStyle } from "modules/setup";
import { connect } from "react-redux";
import SelectField from "components/SelectField";
import LabelWithTooltip from "components/LabelWithTooltip";
import CheckboxField from "components/CheckboxField";
import InputNumberField from "components/InputNumberField";
import useFormSubmission from "hooks/useFormSubmission";

let contains = {
  widgetPosition: ["Right", "Left"],
  highlightColor: ["White", "Black"]
};

function StyleFormContainer({
  values,
  dirty,
  setSubmitDisabled,
  submitDisabled,
  setStyle,
  handleSubmit
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);
  useEffect(() => {
    setStyle(values);

    if (!dirty) {
      if (!submitDisabled) {
        setSubmitDisabled(true);
      }
    } else if (submitDisabled) {
      setSubmitDisabled(false);
    }
  }, [values]);

  return (
    <Form onSubmit={handleSubmitForm}>
      <GutterField>
        <FastField
          name="brandColor"
          label="Brand color"
          placeholder="Select brand color"
          tooltip="The color which is used as the primary for the widget's button and for elements."
          component={SelectColorField}
        />
      </GutterField>
      <GutterField>
        <FastField
          name="highlightColor"
          label="Highlight color"
          component={SelectField}
          items={contains.highlightColor.map(item => {
            return { label: item, value: item };
          })}
        />
      </GutterField>
      <GutterField>
        <FastField
          name="position"
          label="Widget position"
          component={SelectField}
          items={contains.widgetPosition.map(item => {
            return { label: item, value: item };
          })}
        />
      </GutterField>
      <GutterField>
        <Row>
          <Col column gutterRight={5}>
            <FastField
              type="number"
              label="Side Spacing"
              tooltip="Define the value for the widget's position against the edge of the screen in px. The maximum possible value  is 40 px, minimum - 0 px."
              name="sideSpacing"
              maxValue={40}
              component={InputNumberField}
            />
          </Col>
          <Col column gutterLeft={5}>
            <FastField
              label="Bottom Spacing"
              tooltip="Define the value of the widget's position against the bottom edge of the screen in px. The maximum possible value  is 40 px, minimum - 0 px."
              name="bottomSpacing"
              type="number"
              maxValue={40}
              component={InputNumberField}
            />
          </Col>
        </Row>
      </GutterField>
      <GutterField>
        <Row>
          <FastField
            name="includeCopyrights"
            disabled={true}
            size="sm"
            labelText="Include copyrights"
            isChecked={values.includeCopyrights}
            component={CheckboxField}
          />
          <LabelWithTooltip
            noGutter
            tooltip="To unlock this option, contact our sales department."
          />
        </Row>
      </GutterField>
    </Form>
  );
}

const mapDispatchToProps = dispatch => ({
  setStyle: values => dispatch(setStyle(values))
});

export default connect(
  null,
  mapDispatchToProps
)(StyleFormContainer);
