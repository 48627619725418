"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _TableModule = _interopRequireDefault(require("./Table.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function TableColumn(_ref) {
  var justified = _ref.justified,
      fixed = _ref.fixed,
      isAvatar = _ref.isAvatar,
      noPadding = _ref.noPadding,
      width = _ref.width,
      active = _ref.active,
      isSwitcher = _ref.isSwitcher,
      children = _ref.children,
      attributes = _objectWithoutProperties(_ref, ["justified", "fixed", "isAvatar", "noPadding", "width", "active", "isSwitcher", "children"]);

  var classes = (0, _classnames["default"])(_TableModule["default"].tableColumn, isAvatar && _TableModule["default"].isAvatar, justified && _TableModule["default"].justified, fixed && _TableModule["default"].fixed, noPadding && _TableModule["default"].p0, active && _TableModule["default"].active, isSwitcher && _TableModule["default"].isSwitcher);
  return _react["default"].createElement("div", _extends({
    className: classes,
    style: {
      width: width,
      minWidth: width
    }
  }, attributes), children);
}

TableColumn.propTypes = {
  justified: _propTypes["default"].bool,
  fixed: _propTypes["default"].bool,
  noPadding: _propTypes["default"].bool,
  width: _propTypes["default"].number
};
TableColumn.defaultProps = {
  justified: false,
  fixed: false,
  noPadding: false,
  width: 160
};
var _default = TableColumn;
exports["default"] = _default;