"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactDatepicker = _interopRequireWildcard(require("react-datepicker"));

var _enGB = _interopRequireDefault(require("date-fns/locale/en-GB"));

require("./DatePicker.css");

var _DatePickerModule = _interopRequireDefault(require("./DatePicker.module.scss"));

var _Input = _interopRequireDefault(require("../Input/Input"));

var _classnames = _interopRequireDefault(require("classnames"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

(0, _reactDatepicker.registerLocale)("en-GB", _enGB["default"]);

function DatePickerSingle(_ref) {
  var size = _ref.size,
      label = _ref.label,
      placeholder = _ref.placeholder;
  var pickerRef = (0, _react.useRef)(null);

  var _useState = (0, _react.useState)(null),
      _useState2 = _slicedToArray(_useState, 2),
      startDate = _useState2[0],
      setStartDate = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isFocus = _useState4[0],
      setFocus = _useState4[1];

  (0, _react.useEffect)(function () {
    document.addEventListener("click", function () {
      var calendar = document.querySelector(".react-datepicker-popper");

      if (document.body.contains(calendar)) {
        setFocus(true);
      } else {
        setFocus(false);
      }
    });
  }, []);
  var handleChange = (0, _react.useCallback)(function (date) {
    setStartDate(date);
  }, [startDate]);
  var classes = (0, _classnames["default"])(_DatePickerModule["default"].datePickerSingle, isFocus && _DatePickerModule["default"].isFocus);
  var iconClasses = (0, _classnames["default"])(_DatePickerModule["default"].iconDate, size === "sm" && _DatePickerModule["default"].iconSm, size === "lg" && _DatePickerModule["default"].iconLg);
  return _react["default"].createElement("span", {
    className: classes
  }, _react["default"].createElement(_reactDatepicker["default"], {
    selected: startDate,
    onChange: handleChange,
    popperModifiers: {
      offset: {
        enabled: false
      }
    },
    ref: pickerRef,
    dateFormat: "dd.MM.yyyy",
    locale: "en-GB",
    placeholderText: placeholder,
    customInput: _react["default"].createElement(_Input["default"], {
      onClick: true,
      size: size,
      label: label,
      appendAfter: startDate ? null : _react["default"].createElement("span", {
        className: iconClasses
      }, _react["default"].createElement("i", {
        className: "icon-date-icon"
      })),
      onClearClick: function onClearClick() {
        return setStartDate(null);
      }
    })
  }));
}

DatePickerSingle.defaultProps = {
  now: _propTypes["default"].bool,
  label: _propTypes["default"].string,
  placeholder: _propTypes["default"].string
};
var _default = DatePickerSingle;
exports["default"] = _default;