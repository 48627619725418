import React, { useCallback } from "react";
import { Checkbox } from "design-system-react";

function CheckboxField({
  field: { name },
  form: { setFieldValue },
  size,
  labelText,
  isChecked,
  disabled
}) {
  const handleChange = useCallback(() => {
    setFieldValue(name, !isChecked);
  }, [isChecked]);

  return (
    <Checkbox
      name={name}
      isChecked={isChecked}
      labelText={labelText}
      size={size}
      disabled={disabled}
      onChange={handleChange}
    />
  );
}

export default CheckboxField;
