import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { setChannels } from "modules/setup";
import useFormSubmission from "hooks/useFormSubmission";
import ChannelListFormContainer from "./ChannelListFormContainer";

function ChannelsFormContainer({
  setChannels,
  handleSubmit,
  values,
  dirty,
  submitDisabled,
  setSubmitDisabled,
  handleSetModal,
  modalValue,
  setValues
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);

  // update setup on form changes
  useEffect(() => {
    setChannels(values);

    if (!dirty) {
      if (!submitDisabled) {
        setSubmitDisabled(true);
      }
    } else if (submitDisabled) {
      setSubmitDisabled(false);
    }
  }, [values]);

  useEffect(() => {
    if (modalValue) {
      let filterVal = values.channels.map(i => {
        return {
          type: i.type,
          link: i.type === modalValue.type ? modalValue.link : i.link,
          enabled: i.type === modalValue.type ? modalValue.enabled : i.enabled
        };
      });
      setValues({ channels: filterVal });
    }
  }, [modalValue]);

  const changeList = useCallback(
    values => {
      setValues({ channels: values.list });
    },
    [values]
  );

  const handleDisconnect = useCallback(
    type => {
      let filterVal = values.channels.map(i => {
        return {
          type: i.type,
          link: i.type === type ? "" : i.link,
          enabled: i.type === type ? false : i.enabled
        };
      });
      setValues({ channels: filterVal });
    },
    [values]
  );

  return (
    <ChannelListFormContainer
      handleSubmitForm={handleSubmitForm}
      channelList={values.channels}
      handleSetModal={handleSetModal}
      handleDisconnect={handleDisconnect}
      changeList={changeList}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  setChannels: values => dispatch(setChannels(values))
});

export default connect(
  null,
  mapDispatchToProps
)(ChannelsFormContainer);
