import React from "react";
import { RadioButton } from "design-system-react";

function RadioButtonField({
  field: { name, value, onChange },
  size,
  label,
  disabled,
  id,
  ...props
}) {
  return (
    <RadioButton
      name={name}
      id={id}
      isChecked={id === value}
      onChange={onChange}
      labelText={label}
      size={size}
      disabled={disabled}
      {...props}
    />
  );
}

export default RadioButtonField;
