"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function DropdownItemWithAvatar(_ref) {
  var renderAvatar = _ref.renderAvatar,
      children = _ref.children;
  return _react["default"].createElement("div", {
    className: _DropdownModule["default"].withAvatar
  }, _react["default"].createElement("div", {
    className: _DropdownModule["default"].avatar
  }, renderAvatar), _react["default"].createElement("div", {
    className: _DropdownModule["default"].content
  }, children));
}

var _default = DropdownItemWithAvatar;
exports["default"] = _default;