import { withNamespace } from "utils/namespace";
import { setToken } from "utils/client";

const type = withNamespace("auth");

export const SET_USER_ID = type("SET_USER_ID");

let initialState = {
  userId: localStorage.getItem("userId") || sessionStorage.getItem("userId")
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_ID:
      return {
        userId: action.payload.id
      };
    default:
      return state;
  }
}

export function logout() {
  return dispatch => {
    localStorage.clear();
    sessionStorage.clear();

    setToken(null);
    dispatch(setUserId(null));
  };
}

export function setUserId(id) {
  return {
    type: SET_USER_ID,
    payload: {
      id
    }
  };
}
