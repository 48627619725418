"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _TableModule = _interopRequireDefault(require("./Table.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function TableHeader(props) {
  var classes = (0, _classnames["default"])(_TableModule["default"].tableHeader, props.fixed && _TableModule["default"].fixed);
  return _react["default"].createElement("div", {
    className: classes
  }, props.children);
}

TableHeader.propTypes = {
  fixed: _propTypes["default"].bool
};
var _default = TableHeader;
exports["default"] = _default;