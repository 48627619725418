"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rightMonthIcon = exports.leftMonthIcon = exports.leftYearIcon = exports.rightYearIcon = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var rightYearIcon = _react["default"].createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "11.97",
  height: "15",
  viewBox: "0 0 11.97 15"
}, _react["default"].createElement("path", {
  fill: "#7d7e80",
  d: "M1171.14,2113.79l6.69,6.69-6.69,6.7a0.483,0.483,0,0,0,0,.67,0.477,0.477,0,0,0,.68,0l6.97-6.99a0.107,0.107,0,0,0,.06-0.02,0.5,0.5,0,0,0,.14-0.36,0.491,0.491,0,0,0-.14-0.35,0.333,0.333,0,0,0-.06-0.03l-6.97-6.99A0.481,0.481,0,1,0,1171.14,2113.79Zm4,0,6.69,6.69-6.69,6.7a0.483,0.483,0,0,0,0,.67,0.477,0.477,0,0,0,.68,0l6.97-6.99a0.107,0.107,0,0,0,.06-0.02,0.5,0.5,0,0,0,.14-0.36,0.491,0.491,0,0,0-.14-0.35,0.333,0.333,0,0,0-.06-0.03l-6.97-6.99A0.481,0.481,0,1,0,1175.14,2113.79Z",
  transform: "translate(-1171.03 -2113)"
}));

exports.rightYearIcon = rightYearIcon;

var leftYearIcon = _react["default"].createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "15",
  viewBox: "0 0 12 15"
}, _react["default"].createElement("path", {
  fill: "#7d7e80",
  d: "M954.856,2113.79l-6.686,6.69,6.686,6.7a0.474,0.474,0,0,1,0,.67,0.466,0.466,0,0,1-.671,0l-6.978-6.99a0.146,0.146,0,0,1-.062-0.02,0.484,0.484,0,0,1-.135-0.36,0.477,0.477,0,0,1,.135-0.35c0.018-.02.043-0.01,0.062-0.03l6.978-6.99A0.478,0.478,0,1,1,954.856,2113.79Zm-4,0-6.686,6.69,6.686,6.7a0.474,0.474,0,0,1,0,.67,0.466,0.466,0,0,1-.671,0l-6.978-6.99a0.146,0.146,0,0,1-.062-0.02,0.484,0.484,0,0,1-.135-0.36,0.477,0.477,0,0,1,.135-0.35c0.018-.02.043-0.01,0.062-0.03l6.978-6.99A0.478,0.478,0,1,1,950.856,2113.79Z",
  transform: "translate(-943 -2113)"
}));

exports.leftYearIcon = leftYearIcon;

var leftMonthIcon = _react["default"].createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "8",
  height: "15",
  viewBox: "0 0 8 15"
}, _react["default"].createElement("path", {
  fill: "#7d7e80",
  d: "M976.856,2113.79l-6.686,6.69,6.686,6.7a0.474,0.474,0,0,1,0,.67,0.466,0.466,0,0,1-.671,0l-6.978-6.99a0.15,0.15,0,0,1-.062-0.02,0.484,0.484,0,0,1-.135-0.36,0.477,0.477,0,0,1,.135-0.35c0.018-.02.044-0.01,0.062-0.03l6.978-6.99A0.478,0.478,0,1,1,976.856,2113.79Z",
  transform: "translate(-969 -2113)"
}));

exports.leftMonthIcon = leftMonthIcon;

var rightMonthIcon = _react["default"].createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "7.97",
  height: "15",
  viewBox: "0 0 7.97 15"
}, _react["default"].createElement("path", {
  fill: "#7d7e80",
  d: "M1149.14,2113.79l6.69,6.69-6.69,6.7a0.483,0.483,0,0,0,0,.67,0.477,0.477,0,0,0,.68,0l6.97-6.99a0.107,0.107,0,0,0,.06-0.02,0.5,0.5,0,0,0,.14-0.36,0.491,0.491,0,0,0-.14-0.35,0.333,0.333,0,0,0-.06-0.03l-6.97-6.99A0.481,0.481,0,1,0,1149.14,2113.79Z",
  transform: "translate(-1149.03 -2113)"
}));

exports.rightMonthIcon = rightMonthIcon;