import React, { useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { setIntroduce, setSetup } from "modules/setup";
import IntroduceFormContainer from "pages/Introduce/containers/IntroduceFormContainer";
import { Formik } from "formik";
import { api } from "utils/client";
import { STEP_INTRODUCE, STEP_STYLE } from "helpers/steps";
import { ALERT_SUCCESS, showAlert } from "utils/alertManager";
import { FormError } from "utils/formError";
import useStepPage from "hooks/useStepPage";
import FooterContainer from "containers/FooterContainer";

function IntroduceContainer({ setup, setIntroduce, setSetup }) {
  const page = useStepPage();

  //initial form values
  const initialValues = useMemo(() => {
    return {
      defaultLanguage: setup.introduce.defaultLanguage || "en",
      languages: setup.introduce.languages || [],
      company: setup.introduce.company || "",
      logo: setup.introduce.logo || "",
      showAgents: String(setup.introduce.showAgents) === "false" ? false : true
    };
  }, []);

  // on mount/unmount
  useEffect(() => {
    document.title = "Introduce";

    if (setup.step > STEP_INTRODUCE) {
      page.form.current.validateForm();
    }

    page.currentInitial = initialValues;

    return () => {
      // reset form
      setIntroduce(page.currentInitial);
    };
  }, []);

  // client form validation
  const validateForm = useCallback(
    values => {
      let errors = {};

      if (!values.company) {
        errors.company = "Required field";
      }

      if (Object.keys(errors).length === 0) {
        if (!page.validForm) {
          page.setValidForm(true);
        }
      } else if (page.validForm) {
        page.setValidForm(false);
      }

      return errors;
    },
    [page.validForm]
  );

  // submit form
  const handleSubmitForm = useCallback(
    (values, { setErrors, resetForm }) => {
      if (!page.loading) {
        page.setLoading(true);

        const formData = new FormData();
        formData.append("defaultLanguage", values.defaultLanguage);
        for (let i = 0; i < values.languages.length; i++) {
          formData.append("languages[]", values.languages[i]);
        }
        formData.append("logo", values.logo);
        formData.append("company", values.company);
        formData.append("showAgents", values.showAgents);

        api.setups
          .updateIntroduce(setup.id, formData, setup.step > STEP_INTRODUCE)
          .ready.then(response => {
            page.currentInitial = {
              defaultLanguage:
                response.data.data.introduce.defaultLanguage || "en",
              languages: response.data.data.introduce.languages || [],
              company: response.data.data.introduce.company || "",
              logo: response.data.data.introduce.logo || "",
              showAgents: response.data.data.introduce.showAgents
            };
            setSetup(response.data.data);
            if (response.data.data.step === STEP_STYLE) {
              page.setRedirectToNextStep(true);
            } else {
              showAlert(
                ALERT_SUCCESS,
                "Introduce your company saved successfully"
              );
            }
            page.setInitialSaved(true);
            page.setSubmitDisabled(true);
            resetForm(values);
          })
          .catch(e => {
            if (e instanceof FormError) {
              setErrors(e.errors);
            }
          })
          .finally(() => {
            page.setLoading(false);
          });
      }
    },
    [page.loading]
  );

  const renderForm = useCallback(
    props => (
      <IntroduceFormContainer
        {...props}
        submitDisabled={page.submitDisabled}
        setSubmitDisabled={page.setSubmitDisabled}
      />
    ),
    [page.submitDisabled]
  );
  if (page.redirectToNextStep) {
    return <Redirect to={"/setups/" + setup.id + "/update-style"} />;
  }

  return (
    <>
      <Formik
        ref={page.form}
        validate={validateForm}
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={renderForm}
      />
      <FooterContainer
        updateStep={setup.step > STEP_INTRODUCE}
        color={setup.style.brandColor}
        disabled={!page.validForm || page.submitDisabled}
        loading={page.loading}
        onNextClick={page.handleNextClick}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.setup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setIntroduce: introduce => dispatch(setIntroduce(introduce))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntroduceContainer);
