"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reactDom = _interopRequireDefault(require("react-dom"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _SidePanelModule = _interopRequireDefault(require("./SidePanel.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var sidePanelRoot = document.getElementById("side-panel");

if (!sidePanelRoot) {
  sidePanelRoot = document.createElement("div");
  sidePanelRoot.id = "side-panel";
  document.body.appendChild(sidePanelRoot);
}

function SidePanel(_ref) {
  var isOpen = _ref.isOpen,
      children = _ref.children,
      width = _ref.width,
      onClose = _ref.onClose,
      noPadding = _ref.noPadding;
  var handleOverlayClick = (0, _react.useCallback)(function () {
    if (isOpen) {
      onClose && onClose();
    }
  }, [isOpen]);
  var handleSidePanelClick = (0, _react.useCallback)(function (e) {
    e.stopPropagation();
  }, []);
  var classes = (0, _classnames["default"])(_SidePanelModule["default"].sidePanelOverlay, isOpen && _SidePanelModule["default"].open, noPadding && _SidePanelModule["default"].noPadding);
  return _reactDom["default"].createPortal(_react["default"].createElement("div", {
    className: classes,
    onClick: handleOverlayClick
  }, _react["default"].createElement("div", {
    className: _SidePanelModule["default"].sidePanel,
    style: {
      width: width
    },
    onClick: handleSidePanelClick
  }, children)), sidePanelRoot);
}

SidePanel.propTypes = {
  isOpen: _propTypes["default"].bool,
  width: _propTypes["default"].string,
  onClose: _propTypes["default"].func
};
SidePanel.defaultProps = {
  isOpen: false,
  width: "400px"
};
var _default = SidePanel;
exports["default"] = _default;