"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

var _DropdownContext = require("./DropdownContext");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function DropdownToggleDefault(_ref) {
  var label = _ref.label,
      children = _ref.children,
      placeholder = _ref.placeholder,
      filter = _ref.filter,
      filterRef = _ref.filterRef,
      onFilterChange = _ref.onFilterChange;
  var context = (0, _react.useContext)(_DropdownContext.DropdownContext);
  return _react["default"].createElement(_react["default"].Fragment, null, label && _react["default"].createElement("label", {
    className: _DropdownModule["default"].label
  }, label), _react["default"].createElement("div", {
    className: _DropdownModule["default"].formControl,
    tabIndex: 0
  }, _react["default"].createElement("div", {
    className: _DropdownModule["default"].input
  }, onFilterChange && _react["default"].createElement("input", {
    ref: filterRef,
    className: _DropdownModule["default"].filterInput,
    tabIndex: 0,
    value: filter,
    onChange: onFilterChange,
    onClick: function onClick(e) {
      context.isOpen && e.stopPropagation();
    }
  }), children, !children && !context.isOpen && _react["default"].createElement("div", {
    className: _DropdownModule["default"].placeholder
  }, placeholder)), _react["default"].createElement("div", {
    className: _DropdownModule["default"].arrowButton
  }, _react["default"].createElement("i", {
    className: "icon-arrow"
  }))));
}

DropdownToggleDefault.propTypes = {
  label: _propTypes["default"].string,
  placeholder: _propTypes["default"].string,
  filter: _propTypes["default"].string,
  onFilterChange: _propTypes["default"].string
};
DropdownToggleDefault.defaultProps = {
  label: "",
  placeholder: "",
  filter: "",
  onFilterChange: null
};
var _default = DropdownToggleDefault;
exports["default"] = _default;