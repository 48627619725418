import React, { useRef, useMemo, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Field, Formik } from "formik";
import { Button, GutterField } from "design-system-react";
import RadioButtonsContainer from "containers/RadioButtonsContainer";
import LoginFooter from "components/Login/LoginFooter";
import LoginForm from "components/Login/LoginForm";
import InputField from "components/InputField";
import { api } from "utils/client";
import { FormError } from "utils/formError";

function GetStartedFormContainer({ signUpValues, setSignUpValues, ...props }) {
  const form = useRef(null);

  const initialValues = useMemo(() => {
    return {
      email: signUpValues.email || "",
      password: signUpValues.password || "",
      ConfirmPassword: signUpValues.ConfirmPassword || "",
      mailing: signUpValues.mailing || true
    };
  }, []);

  const submitStep = useCallback((values, { setErrors, setSubmitting }) => {
    setSubmitting(true);

    api.customers
      .validateCredentials(values)
      .ready.then(() => {
        setSignUpValues(Object.assign(signUpValues, values));
        props.history.push("/sign-up/2");
      })
      .catch(e => {
        if (e instanceof FormError) {
          setErrors(e.errors);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, []);

  return (
    <LoginForm>
      <Formik
        ref={form}
        initialValues={initialValues}
        onSubmit={submitStep}
        render={props => {
          return (
            <form>
              <h1>Get started with business email</h1>
              <GutterField>
                <Field
                  name="email"
                  type="email"
                  label="Business email"
                  placeholder="Email"
                  component={InputField}
                />
              </GutterField>
              <GutterField>
                <Field
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Enter your password"
                  component={InputField}
                />
              </GutterField>
              <GutterField>
                <Field
                  name="ConfirmPassword"
                  type="password"
                  label="Confirm password"
                  placeholder="Re-enter your password"
                  component={InputField}
                />
              </GutterField>
              <GutterField>
                <Field
                  name="mailing"
                  type="radio"
                  placeholder="Please also send me occasional emails about Blinger
products and services."
                  defaultValue={true}
                  nameForm="mailing"
                  component={RadioButtonsContainer}
                />
              </GutterField>
              <LoginFooter>
                <Button
                  type="submit"
                  disabled={props.isSubmitting}
                  onClick={props.handleSubmit}
                >
                  Next
                </Button>
              </LoginFooter>
            </form>
          );
        }}
      />
    </LoginForm>
  );
}

export default withRouter(GetStartedFormContainer);
