import React, { useRef, useMemo, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import LoginForm from "components/Login/LoginForm";
import { api } from "utils/client";
import { FormError } from "utils/formError";
import AboutCompanyFormContainer from "./AboutCompanyFormContainer";

function AboutCompanyContainer({ signUpValues, setSignUpValues, ...props }) {
  const form = useRef(null);

  const initialValues = useMemo(() => {
    return {
      companyName: signUpValues.companyName || "",
      employeesCount: signUpValues.employeesCount || "",
      vertical: signUpValues.vertical || ""
    };
  }, []);

  const submitStep = useCallback((values, { setSubmitting, setErrors }) => {
    setSubmitting(true);

    api.customers
      .validateCompany(values)
      .ready.then(() => {
        setSignUpValues(Object.assign(signUpValues, values));
        props.history.push("/sign-up/3");
      })
      .catch(e => {
        if (e instanceof FormError) {
          setErrors(e.errors);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, []);

  const prevStep = useCallback(() => {
    props.history.push("/sign-up/1");
  }, []);

  return (
    <LoginForm>
      <Formik
        ref={form}
        initialValues={initialValues}
        onSubmit={submitStep}
        render={props => {
          return (
            <AboutCompanyFormContainer
              values={props.values}
              isSubmitting={props.isSubmitting}
              prevStep={prevStep}
              handleSubmit={props.handleSubmit}
            />
          );
        }}
      />
    </LoginForm>
  );
}

export default withRouter(AboutCompanyContainer);
