"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _InputTextButtonModule = _interopRequireDefault(require("./InputTextButton.module.scss"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function InputTextButton(_ref) {
  var label = _ref.label,
      onClick = _ref.onClick;
  return _react["default"].createElement("button", {
    className: _InputTextButtonModule["default"].inputTextButton,
    onClick: onClick
  }, label);
}

InputTextButton.propTypes = {
  label: _propTypes["default"].string.isRequired,
  onClick: _propTypes["default"].func.isRequired
};
var _default = InputTextButton;
exports["default"] = _default;