import React, { useState, useRef, useCallback } from "react";
import styles from "./TextareaWrap.module.css";

function TextareaWrap({ children }) {
  const [isCopied, setCopy] = useState(false);
  const wrapRef = useRef(null);

  const copyToClipboard = useCallback(() => {
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    wrapRef.current.querySelector("textarea").select();
    document.execCommand("copy");
    setCopy(true);
    setTimeout(() => setCopy(false), 2000);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  }, []);

  return (
    <div ref={wrapRef} className={styles.wrap}>
      <button
        disabled={isCopied}
        onClick={copyToClipboard}
        className={styles.copy}
      >
        {(isCopied && "Copied") || "Copy"}
      </button>
      {children}
    </div>
  );
}

export default TextareaWrap;
