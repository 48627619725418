import React from "react";
import styles from "./styles.module.css";

function PickerColorBox({ color }) {
  return (
    <div className={styles.boxWrap}>
      <span className={styles.box} style={{ background: color }} />
      <span className={styles.text}>{color}</span>
    </div>
  );
}

export default PickerColorBox;
