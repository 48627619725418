import React from "react";
import classNames from "classnames";
import styles from "./MenuListSuccessIcon.module.css";

function MenuListSuccessIcon({ active }) {
  const classes = classNames(styles.icon, active && styles.active);
  return (
    <svg
      className={classes}
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      style={{ enableBackground: "new 0 0 50 50" }}
    >
      <circle style={{ fill: "#d4d5d9" }} cx="25" cy="25" r="25" />
      <polyline
        style={{ fill: "none", stroke: "#000000" }}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        points="
    38,15 22,33 12,25 "
      />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
}

export default MenuListSuccessIcon;
