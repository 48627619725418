import React, { useState, useCallback } from "react";
import ChannelsPageContainer from "./ChannelsPageContainer";
import ChannelModalContainer from "pages/Channels/containers/ChannelModalContainer";

function ChannelsContainer() {
  const [modalValue, setModalValue] = useState(null);
  const [modalParams, setModalParams] = useState({
    isOpen: false,
    link: null,
    type: null
  });

  const handleSetModal = useCallback(
    (link, type) => {
      setModalParams({ isOpen: true, link, type });
    },
    [modalParams]
  );

  const handleSetValues = useCallback(
    modalValue => {
      setModalValue(modalValue);
    },
    [modalValue]
  );

  const handleModalClose = useCallback(() => {
    setModalParams({
      isOpen: false,
      link: modalParams.link,
      type: modalParams.type
    });
  }, [modalParams]);

  return (
    <>
      <ChannelModalContainer
        close={handleModalClose}
        modalParams={modalParams}
        setValues={handleSetValues}
      />
      <ChannelsPageContainer
        modalValue={modalValue}
        handleSetModal={handleSetModal}
      />
    </>
  );
}

export default ChannelsContainer;
