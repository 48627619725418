import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MessengerCheckbox from "pages/Channels/components/MessengerCheckbox";
import { defineChannel } from "utils/defineChannel";
import Form from "components/Form";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ChannelList = React.memo(function ChannelList({
  list,
  handleSetModal,
  handleDisconnect
}) {
  return list.map((channel, index) => {
    return (
      <Draggable
        isDragDisabled={!channel.enabled}
        draggableId={channel.type}
        key={channel.type}
        index={index}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <MessengerCheckbox
              messenger={channel.type}
              name={defineChannel(channel.type).name}
              connection={channel.enabled}
              link={channel.link}
              handleClickConnect={() => {
                handleSetModal(channel.link, channel.type);
              }}
              handleClickDisconnect={() => {
                handleDisconnect(channel.type);
              }}
            />
          </div>
        )}
      </Draggable>
    );
  });
});

function ChannelListFormContainer({
  channelList,
  changeList,
  handleSubmitForm,
  handleSetModal,
  handleDisconnect
}) {
  const sortedList = channelList.sort((a, b) => {
    if (a.enabled > b.enabled) {
      return -1;
    } else if (a.enabled === b.enabled) {
      return 0;
    } else {
      return 1;
    }
  });

  const [isDrag, setDrag] = useState(false);

  const onDragStart = () => {
    setDrag(true);
  };

  function onDragEnd(result) {
    setDrag(false);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const list = reorder(
      sortedList,
      result.source.index,
      result.destination.index
    );

    changeList({ list });
  }

  return (
    <Form isDrag={isDrag} onSubmit={handleSubmitForm}>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <ChannelList
                list={channelList}
                handleSetModal={handleSetModal}
                handleDisconnect={handleDisconnect}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Form>
  );
}

export default ChannelListFormContainer;
