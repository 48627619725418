import React from "react";
import { Route } from "react-router-dom";
import AppLayout from "layouts/AppLayout";
import { connect } from "react-redux";

function AppLayoutRoute({ component: Component, userId, ...props }) {
  return (
    <Route
      exact
      {...props}
      render={matchProps => (
        <AppLayout>
          <Component {...matchProps} />
        </AppLayout>
      )}
    />
  );
}

const mapStateToProps = state => {
  return {
    userId: state.auth.userId
  };
};

export default connect(mapStateToProps)(AppLayoutRoute);
