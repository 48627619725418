import React from "react";
import styles from "./MenuList.module.css";

function MenuList({ children }) {
  return (
    <nav className={styles.navigation}>
      <ul className={styles.list}>{children}</ul>
    </nav>
  );
}

export default MenuList;
