"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _reactDom = _interopRequireDefault(require("react-dom"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _ModalModule = _interopRequireDefault(require("./Modal.module.scss"));

var _Button = _interopRequireDefault(require("../Button/Button"));

var _Preloader = _interopRequireDefault(require("../Preloader/Preloader"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var modalRoot = document.getElementById("modal");

if (!modalRoot) {
  modalRoot = document.createElement("div");
  modalRoot.id = "modal";
  document.body.appendChild(modalRoot);
}

function Modal(_ref) {
  var isOpen = _ref.isOpen,
      status = _ref.status,
      className = _ref.className,
      children = _ref.children,
      actionButtonText = _ref.actionButtonText,
      retryButtonText = _ref.retryButtonText,
      cancelButtonText = _ref.cancelButtonText,
      successButtonText = _ref.successButtonText,
      onClose = _ref.onClose,
      onRetry = _ref.onRetry,
      onAction = _ref.onAction;
  var handleOverlayClick = (0, _react.useCallback)(function () {
    if (isOpen) {
      onClose && onClose();
    }
  }, [isOpen]);
  var classes = (0, _classnames["default"])(_ModalModule["default"].modalOverlay, isOpen && _ModalModule["default"].open, className);
  return _reactDom["default"].createPortal(_react["default"].createElement("div", {
    className: classes,
    onClick: handleOverlayClick
  }, _react["default"].createElement("div", {
    className: _ModalModule["default"].modal,
    onClick: function onClick(e) {
      e.stopPropagation();
    }
  }, _react["default"].createElement("div", {
    className: _ModalModule["default"].modalBody
  }, (status === "process" || status === "success" || status === "error") && _react["default"].createElement("div", {
    className: _ModalModule["default"].modalBodyIcon
  }, status === "process" && _react["default"].createElement(_Preloader["default"], {
    size: "36px",
    color: "#7d7e80"
  }), status === "success" && _react["default"].createElement("div", {
    className: _ModalModule["default"].successIcon
  }, _react["default"].createElement("i", {
    className: "icon-success-icon"
  })), status === "error" && _react["default"].createElement("div", {
    className: _ModalModule["default"].errorIcon
  }, _react["default"].createElement("i", {
    className: "icon-error-icon"
  }))), _react["default"].createElement("div", {
    className: _ModalModule["default"].modalBodyText
  }, _react["default"].createElement("p", null, children))), _react["default"].createElement("div", {
    className: _ModalModule["default"].modalFooter
  }, ["process", "error", "success"].indexOf(status) === -1 && _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_Button["default"], {
    onClick: onClose,
    outline: true
  }, cancelButtonText), _react["default"].createElement(_Button["default"], {
    onClick: onAction
  }, actionButtonText)), status === "error" && _react["default"].createElement(_react["default"].Fragment, null, onRetry ? _react["default"].createElement(_Button["default"], {
    onClick: onRetry,
    outline: true
  }, retryButtonText) : _react["default"].createElement(_Button["default"], {
    onClick: onClose,
    outline: true
  }, cancelButtonText)), status === "success" && _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_Button["default"], {
    onClick: onClose,
    outline: true
  }, successButtonText))), _react["default"].createElement("div", {
    className: _ModalModule["default"].closeButton,
    onClick: onClose
  }, _react["default"].createElement("i", {
    className: "icon-close"
  })))), modalRoot);
}

Modal.propTypes = {
  isOpen: _propTypes["default"].bool,
  status: _propTypes["default"].string,
  children: _propTypes["default"].node,
  actionButtonText: _propTypes["default"].string,
  retryButtonText: _propTypes["default"].string,
  cancelButtonText: _propTypes["default"].string,
  successButtonText: _propTypes["default"].string,
  onClose: _propTypes["default"].func,
  onRetry: _propTypes["default"].func,
  onAction: _propTypes["default"].func
};
Modal.defaultProps = {
  isOpen: false,
  status: "",
  actionButtonText: "",
  retryButtonText: "",
  cancelButtonText: "",
  successButtonText: ""
};
var _default = Modal;
exports["default"] = _default;