"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _GridModule = _interopRequireDefault(require("./Grid.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function Row(_ref) {
  var children = _ref.children,
      gutters = _ref.gutters,
      column = _ref.column,
      fullHeight = _ref.fullHeight,
      nowrap = _ref.nowrap,
      align = _ref.align,
      verticalAlign = _ref.verticalAlign,
      attributes = _objectWithoutProperties(_ref, ["children", "gutters", "column", "fullHeight", "nowrap", "align", "verticalAlign"]);

  var classes = (0, _classnames["default"])(_GridModule["default"].row, !gutters && _GridModule["default"].noGutters, nowrap && _GridModule["default"].noWrap, column && _GridModule["default"].columnDirection, fullHeight && _GridModule["default"].h100, verticalAlign && _GridModule["default"]["align-" + verticalAlign], align && _GridModule["default"]["justify-" + align]);
  return _react["default"].createElement("div", _extends({
    className: classes
  }, attributes), children);
}

Row.propTypes = {
  children: _propTypes["default"].node,
  gutters: _propTypes["default"].bool,
  fullHeight: _propTypes["default"].bool,
  nowrap: _propTypes["default"].bool,
  column: _propTypes["default"].bool
};
var _default = Row;
exports["default"] = _default;