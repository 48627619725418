import React, { useEffect, useCallback } from "react";
import { Field } from "formik/dist/index";
import ContactCheckbox from "pages/Qualify/components/ContactCheckbox";
import SubtitleForm from "pages/Qualify/components/SubtitleForm";
import { GutterField, Row, Col } from "design-system-react";
import { translation_default } from "helpers/translations";
import LanguageDropdown from "containers/LanguageDropdown";
import Form from "components/Form";
import { connect } from "react-redux";
import useFormSubmission from "hooks/useFormSubmission";
import DefaultLanguage from "components/DefaultLanguage";
import { api } from "utils/client";
import { setSetup, setQualify } from "modules/setup";
import TextareaField from "components/TextareaField";
import GroupError from "components/GroupError";
import RadioButtonField from "components/RadioButtonField";
import RadiobuttonFieldGroup from "components/RadiobuttonFieldGroup";

function printLanguage(lang) {
  switch (lang) {
    case "ru":
      return "Russian";
    case "en":
      return "English";
    case "kz":
      return "Kazakh";
    default:
      return "English";
  }
}

function QualifyContainer({
  values,
  dirty,
  submitDisabled,
  setSubmitDisabled,
  handleSubmit,
  setQualify,
  setSetup,
  setup,
  errors
}) {
  const handleSubmitForm = useFormSubmission(submitDisabled, handleSubmit);
  const isAdditional = setup.introduce.languages.length > 0;
  const defaultLanguage = setup.introduce.defaultLanguage;

  useEffect(() => {
    setQualify(values);

    if (!dirty) {
      if (!submitDisabled) {
        setSubmitDisabled(true);
      }
    } else if (submitDisabled) {
      setSubmitDisabled(false);
    }
  }, [values]);

  const handleUpdateTranslations = useCallback(
    ({ toggle }) => {
      api.setups
        .updateTranslations(setup.id, values.translations)
        .ready.then(response => {
          toggle();
          setSetup(response.data.data);
        });
    },
    [values]
  );

  return (
    <Form onSubmit={handleSubmitForm}>
      <GutterField>
        <Row>
          <Col gutterRight={isAdditional && 70}>
            <DefaultLanguage>
              <Field
                name="autoMessage"
                label="Auto message"
                placeholder={translation_default.autoMessage[defaultLanguage]}
                component={TextareaField}
              />
            </DefaultLanguage>
          </Col>
          {isAdditional && (
            <LanguageDropdown
              handleUpdate={handleUpdateTranslations}
              defaultLanguage={defaultLanguage}
            >
              {setup.introduce.languages.map(field => {
                return (
                  <GutterField key={field}>
                    <Field
                      name={`translations.autoMessage.${[field]}`}
                      label={printLanguage(field)}
                      placeholder={translation_default.autoMessage[field]}
                      component={TextareaField}
                    />
                  </GutterField>
                );
              })}
            </LanguageDropdown>
          )}
        </Row>
      </GutterField>
      <SubtitleForm>Add qualifying details</SubtitleForm>
      <Field name="details.name" label="Name" component={ContactCheckbox} />
      <Field name="details.email" label="Email" component={ContactCheckbox} />
      <Field
        name="details.phone"
        label="Phone number"
        component={ContactCheckbox}
      />
      {errors.details && <GroupError>{errors.details}</GroupError>}

      <SubtitleForm>Automation rule</SubtitleForm>
      <RadiobuttonFieldGroup description="Choose when to ask your customers about contact information">
        <Field
          name="rule"
          label="Ask lead data after first customer message"
          id="firstMessage"
          component={RadioButtonField}
        />
        <Field
          name="rule"
          label="Ask lead data at once"
          id="atOnce"
          component={RadioButtonField}
        />
      </RadiobuttonFieldGroup>
    </Form>
  );
}

const mapStateToProps = state => {
  return {
    setup: state.setup.current
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup)),
  setQualify: welcome => dispatch(setQualify(welcome))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualifyContainer);
