"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _TableModule = _interopRequireDefault(require("./Table.module.scss"));

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function Table(_ref) {
  var noHover = _ref.noHover,
      headerBorder = _ref.headerBorder,
      withoutPadding = _ref.withoutPadding,
      children = _ref.children;
  var classes = (0, _classnames["default"])(_TableModule["default"].table, noHover && _TableModule["default"].noHover, headerBorder && _TableModule["default"].headerBorder, withoutPadding && _TableModule["default"].withoutPadding);
  return _react["default"].createElement("div", {
    className: classes
  }, children);
}

Table.propTypes = {
  noHover: _propTypes["default"].node.bool,
  headerBorder: _propTypes["default"].node.bool,
  withoutPadding: _propTypes["default"].node.bool
};
Table.defaultProps = {
  noHover: false,
  headerBorder: false,
  withoutPadding: false
};
var _default = Table;
exports["default"] = _default;