import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownToggleDefault,
  DropdownMenu,
  DropdownItem
} from "design-system-react";

function SelectField({
  field,
  form,
  label,
  items,
  size,
  disabled,
  placeholder,
  renderItem
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleChange = useCallback(value => {
    setIsOpen(false);

    form.setFieldValue(field.name, value);
  }, []);

  const selectedItem = items.filter(i => i.value === field.value)[0];

  const error = form.errors[field.name];

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      validation={error && "error"}
      validationMessage={error}
      nonEmpty={selectedItem}
      size={size}
      disabled={disabled}
    >
      <DropdownToggle>
        <DropdownToggleDefault label={label} placeholder={placeholder}>
          {selectedItem &&
            (renderItem ? renderItem(selectedItem) : selectedItem.label)}
        </DropdownToggleDefault>
      </DropdownToggle>
      <DropdownMenu>
        {items.map(item => (
          <DropdownItem
            key={item.value}
            onClick={() => {
              handleChange(item.value);
            }}
            selected={selectedItem && selectedItem.value === item.value}
          >
            {renderItem ? renderItem(item) : item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default SelectField;
