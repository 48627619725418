import React from "react";
import InputField from "components/InputField";
import { Field } from "formik";
import { Button, GutterField } from "design-system-react";

function ResetPasswordFormContainer({ values, handleSubmit, isSubmitting }) {
  const isEmptyFields = () => {
    return !values.email;
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>Reset password</h1>

      <GutterField>
        <Field name="email" type="email" label="Email" component={InputField} />
      </GutterField>

      <Button
        type="submit"
        disabled={isEmptyFields()}
        processing={isSubmitting}
      >
        Submit
      </Button>
    </form>
  );
}

export default ResetPasswordFormContainer;
