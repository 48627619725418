"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropdownContextProvider = exports.DropdownContext = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var DropdownContext = _react["default"].createContext({
  isOpen: false,
  toggle: function toggle() {}
});

exports.DropdownContext = DropdownContext;
var DropdownContextProvider = DropdownContext.Provider;
exports.DropdownContextProvider = DropdownContextProvider;