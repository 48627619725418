import React from "react";
import styles from "pages/Qualify/components/ContactCheckbox.module.css";

function ContactCheckbox({ label, form, field }) {
  const handleChange = () => {
    if (field.value) {
      form.setFieldValue(field.name, false);
    } else {
      form.setFieldValue(field.name, true);
    }
  };

  const icon = () => {
    switch (field.name) {
      case "details.name":
        return "/images/user-icon.svg";
      case "details.phone":
        return "/images/phone-icon.svg";
      case "details.email":
        return "/images/email-icon.svg";
      default:
        return "/images/user-icon.svg";
    }
  };

  return (
    <label className={styles.check} style={{ opacity: field.value ? 1 : 0.5 }}>
      <img src={icon()} alt="icon" className={styles.icon} />
      <span className={styles.title}>{label}</span>
      <input
        name={field.name}
        type="checkbox"
        value={field.value}
        onChange={handleChange}
        className={styles.checkbox}
      />
      <span className={styles.indicate}>{field.value ? "Hide" : "Unhide"}</span>
    </label>
  );
}

export default ContactCheckbox;
