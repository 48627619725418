import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import MenuContainer from "containers/MenuContainer";
import NavPanelContainer from "containers/NavPanelContainer";
import CustomSide from "components/CustomSide";
import WidgetContainer from "containers/WidgetContainer";
import { setSetup } from "modules/setup";
import ToggableLayout from "components/ToggableLayout";
import ToggableLayoutContent from "components/ToggableLayout/ToggableLayoutContent";
import Container from "components/Container";
import ToggableLayoutContentWrap from "components/ToggableLayout/ToggableLayoutContentWrap";
import LoadingLayout from "components/LoadingLayout";
import FooterContainer from "containers/FooterContainer";

function SetupsLayout({
  setSetup,
  component: Component,
  activeMenuItem,
  setups,
  ...props
}) {
  //states
  const [isLoading, setLoading] = useState(true);
  const [isMenuOpened, setIsSubmenuOpened] = useState(
    localStorage.getItem("showSubMenu") === "true"
  );

  useEffect(() => {
    setLoading(true);
    if (setups) {
      setSetup(setups.find(i => i.id === props.match.params.id));
      setLoading(false);
    }
  }, [setups]);

  //callbacks
  const toggleMenu = useCallback(() => {
    const value = !isMenuOpened;

    localStorage.setItem("showSubMenu", value.toString());
    setIsSubmenuOpened(value);
  }, [isMenuOpened]);

  if (isLoading) {
    return <LoadingLayout />;
  }

  return (
    <ToggableLayout>
      <MenuContainer active={activeMenuItem} />
      <ToggableLayoutContent isMenuOpened={isMenuOpened}>
        <NavPanelContainer
          isMenuOpened={isMenuOpened}
          toggleMenu={toggleMenu}
        />
        <Container>
          <ToggableLayoutContentWrap>
            <CustomSide>
              <Component />
            </CustomSide>
            <WidgetContainer activeMenuItem={props.match.path} />
          </ToggableLayoutContentWrap>
        </Container>
      </ToggableLayoutContent>
    </ToggableLayout>
  );
}

const mapStateToProps = state => {
  return {
    setups: state.setups.setups
  };
};

const mapDispatchToProps = dispatch => ({
  setSetup: setup => dispatch(setSetup(setup))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetupsLayout);
