import React, { useState, useEffect } from "react";
import LoadingLayout from "../components/LoadingLayout";
import Login from "pages/SignUp/components/Login";

function LoginLayout({ children, path }) {
  const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 100);
  // }, []);

  if (isLoading) {
    return <LoadingLayout />;
  }

  return <Login path={path}>{children}</Login>;
}

export default LoginLayout;
