"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.AVATAR_SIZE_100 = exports.AVATAR_SIZE_80 = exports.AVATAR_SIZE_48 = exports.AVATAR_SIZE_40 = exports.AVATAR_SIZE_32 = exports.AVATAR_SIZE_24 = exports.AVATAR_SIZE_20 = exports.AVATAR_SIZE_16 = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _AvatarModule = _interopRequireDefault(require("./Avatar.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var AVATAR_SIZE_16 = "size16";
exports.AVATAR_SIZE_16 = AVATAR_SIZE_16;
var AVATAR_SIZE_20 = "size20";
exports.AVATAR_SIZE_20 = AVATAR_SIZE_20;
var AVATAR_SIZE_24 = "size24";
exports.AVATAR_SIZE_24 = AVATAR_SIZE_24;
var AVATAR_SIZE_32 = "size32";
exports.AVATAR_SIZE_32 = AVATAR_SIZE_32;
var AVATAR_SIZE_40 = "size40";
exports.AVATAR_SIZE_40 = AVATAR_SIZE_40;
var AVATAR_SIZE_48 = "size48";
exports.AVATAR_SIZE_48 = AVATAR_SIZE_48;
var AVATAR_SIZE_80 = "size80";
exports.AVATAR_SIZE_80 = AVATAR_SIZE_80;
var AVATAR_SIZE_100 = "size100";
exports.AVATAR_SIZE_100 = AVATAR_SIZE_100;

function Avatar(props) {
  var size = props.size,
      color = props.color,
      theme = props.theme,
      background = props.background,
      text = props.text,
      image = props.image,
      pending = props.pending,
      status = props.status,
      channel = props.channel,
      noGradient = props.noGradient,
      noName = props.noName,
      className = props.className;
  var classes = (0, _classnames["default"])(_AvatarModule["default"].avatarContainer, _AvatarModule["default"][size], image && _AvatarModule["default"].borderNone, background && _AvatarModule["default"].borderNone, theme === "dark" && _AvatarModule["default"].dark, noGradient && _AvatarModule["default"].noGradient, className);
  return _react["default"].createElement("span", {
    className: classes
  }, _react["default"].createElement("span", {
    className: _AvatarModule["default"].avatar + " " + (!text && !image && !pending && _AvatarModule["default"].noName),
    style: {
      backgroundColor: background,
      color: color
    }
  }, text && !image && !pending && !noName && _react["default"].createElement("p", null, text), image && _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement("img", {
    src: image,
    alt: ""
  }), _react["default"].createElement("span", {
    className: _AvatarModule["default"].gradientAvatar
  })), pending && _react["default"].createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "25",
    height: "24",
    viewBox: "0 0 25 24"
  }, _react["default"].createElement("path", {
    id: "Rounded_Rectangle_1548_copy",
    "data-name": "Rounded Rectangle 1548 copy",
    className: "pending",
    d: "M333.179,408.856l-3.942-3.941a0.491,0.491,0,0,1-.231-0.383,0.405,0.405,0,0,1-.006-0.143V398.5a0.5,0.5,0,0,1,1,0v5.823l3.856,3.856A0.479,0.479,0,0,1,333.179,408.856ZM342.5,404h-6a0.5,0.5,0,0,1,0-1h4.449a11.006,11.006,0,1,0-.71,5H341.3a12,12,0,1,1,.646-5H342v-5.5a0.5,0.5,0,0,1,1,0v6A0.5,0.5,0,0,1,342.5,404Z",
    transform: "translate(-318 -392)"
  }))), status === "online" && _react["default"].createElement("span", {
    className: _AvatarModule["default"].statusOnline
  }), status === "offline" && _react["default"].createElement("span", {
    className: _AvatarModule["default"].statusOffline
  }), channel && _react["default"].createElement("span", {
    className: _AvatarModule["default"].channel
  }, _react["default"].createElement("img", {
    src: channel,
    alt: ""
  })));
}

Avatar.propTypes = {
  size: _propTypes["default"].string,
  color: _propTypes["default"].string,
  background: _propTypes["default"].string,
  text: _propTypes["default"].string,
  image: _propTypes["default"].string,
  channel: _propTypes["default"].string,
  status: _propTypes["default"].string,
  pending: _propTypes["default"].bool,
  noGradient: _propTypes["default"].bool,
  noName: _propTypes["default"].bool
};
Avatar.defaultProps = {
  size: "",
  color: "",
  background: "",
  text: "",
  image: "",
  channel: "",
  status: "",
  pending: false,
  noGradient: false,
  noName: false
};
var _default = Avatar;
exports["default"] = _default;