"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0; // Emoji collection array

var _default = [{
  codes: "1F600",
  "char": "😀",
  name: "grinning face",
  keywords: "face | grin | grinning face",
  category: "Smileys & Emotion"
}, {
  codes: "1F603",
  "char": "😃",
  name: "grinning face with big eyes",
  keywords: "face | grinning face with big eyes | mouth | open | smile",
  category: "Smileys & Emotion"
}, {
  codes: "1F604",
  "char": "😄",
  name: "grinning face with smiling eyes",
  keywords: "eye | face | grinning face with smiling eyes | mouth | open | smile",
  category: "Smileys & Emotion"
}, {
  codes: "1F601",
  "char": "😁",
  name: "beaming face with smiling eyes",
  keywords: "beaming face with smiling eyes | eye | face | grin | smile",
  category: "Smileys & Emotion"
}, {
  codes: "1F606",
  "char": "😆",
  name: "grinning squinting face",
  keywords: "face | grinning squinting face | laugh | mouth | satisfied | smile",
  category: "Smileys & Emotion"
}, {
  codes: "1F605",
  "char": "😅",
  name: "grinning face with sweat",
  keywords: "cold | face | grinning face with sweat | open | smile | sweat",
  category: "Smileys & Emotion"
}, {
  codes: "1F923",
  "char": "🤣",
  name: "rolling on the floor laughing",
  keywords: "face | floor | laugh | rolling | rolling on the floor laughing",
  category: "Smileys & Emotion"
}, {
  codes: "1F602",
  "char": "😂",
  name: "face with tears of joy",
  keywords: "face | face with tears of joy | joy | laugh | tear",
  category: "Smileys & Emotion"
}, {
  codes: "1F642",
  "char": "🙂",
  name: "slightly smiling face",
  keywords: "face | slightly smiling face | smile",
  category: "Smileys & Emotion"
}, {
  codes: "1F643",
  "char": "🙃",
  name: "upside-down face",
  keywords: "face | upside-down",
  category: "Smileys & Emotion"
}, {
  codes: "1F609",
  "char": "😉",
  name: "winking face",
  keywords: "face | wink | winking face",
  category: "Smileys & Emotion"
}, {
  codes: "1F60A",
  "char": "😊",
  name: "smiling face with smiling eyes",
  keywords: "blush | eye | face | smile | smiling face with smiling eyes",
  category: "Smileys & Emotion"
}, {
  codes: "1F607",
  "char": "😇",
  name: "smiling face with halo",
  keywords: "angel | face | fantasy | halo | innocent | smiling face with halo",
  category: "Smileys & Emotion"
}, {
  codes: "1F60D",
  "char": "😍",
  name: "smiling face with heart-eyes",
  keywords: "eye | face | love | smile | smiling face with heart-eyes",
  category: "Smileys & Emotion"
}, {
  codes: "1F618",
  "char": "😘",
  name: "face blowing a kiss",
  keywords: "face | face blowing a kiss | kiss",
  category: "Smileys & Emotion"
}, {
  codes: "1F617",
  "char": "😗",
  name: "kissing face",
  keywords: "face | kiss | kissing face",
  category: "Smileys & Emotion"
}, {
  codes: "1F61A",
  "char": "😚",
  name: "kissing face with closed eyes",
  keywords: "closed | eye | face | kiss | kissing face with closed eyes",
  category: "Smileys & Emotion"
}, {
  codes: "1F619",
  "char": "😙",
  name: "kissing face with smiling eyes",
  keywords: "eye | face | kiss | kissing face with smiling eyes | smile",
  category: "Smileys & Emotion"
}, {
  codes: "1F60B",
  "char": "😋",
  name: "face savoring food",
  keywords: "delicious | face | face savoring food | savouring | smile | yum",
  category: "Smileys & Emotion"
}, {
  codes: "1F61B",
  "char": "😛",
  name: "face with tongue",
  keywords: "face | face with tongue | tongue",
  category: "Smileys & Emotion"
}, {
  codes: "1F61C",
  "char": "😜",
  name: "winking face with tongue",
  keywords: "eye | face | joke | tongue | wink | winking face with tongue",
  category: "Smileys & Emotion"
}, {
  codes: "1F61D",
  "char": "😝",
  name: "squinting face with tongue",
  keywords: "eye | face | horrible | squinting face with tongue | taste | tongue",
  category: "Smileys & Emotion"
}, {
  codes: "1F911",
  "char": "🤑",
  name: "money-mouth face",
  keywords: "face | money | money-mouth face | mouth",
  category: "Smileys & Emotion"
}, {
  codes: "1F917",
  "char": "🤗",
  name: "hugging face",
  keywords: "face | hug | hugging",
  category: "Smileys & Emotion"
}, {
  codes: "1F914",
  "char": "🤔",
  name: "thinking face",
  keywords: "face | thinking",
  category: "Smileys & Emotion"
}, {
  codes: "1F910",
  "char": "🤐",
  name: "zipper-mouth face",
  keywords: "face | mouth | zipper | zipper-mouth face",
  category: "Smileys & Emotion"
}, {
  codes: "1F610",
  "char": "😐",
  name: "neutral face",
  keywords: "deadpan | face | meh | neutral",
  category: "Smileys & Emotion"
}, {
  codes: "1F611",
  "char": "😑",
  name: "expressionless face",
  keywords: "expressionless | face | inexpressive | meh | unexpressive",
  category: "Smileys & Emotion"
}, {
  codes: "1F636",
  "char": "😶",
  name: "face without mouth",
  keywords: "face | face without mouth | mouth | quiet | silent",
  category: "Smileys & Emotion"
}, {
  codes: "1F60F",
  "char": "😏",
  name: "smirking face",
  keywords: "face | smirk | smirking face",
  category: "Smileys & Emotion"
}, {
  codes: "1F612",
  "char": "😒",
  name: "unamused face",
  keywords: "face | unamused | unhappy",
  category: "Smileys & Emotion"
}, {
  codes: "1F644",
  "char": "🙄",
  name: "face with rolling eyes",
  keywords: "eyeroll | eyes | face | face with rolling eyes | rolling",
  category: "Smileys & Emotion"
}, {
  codes: "1F62C",
  "char": "😬",
  name: "grimacing face",
  keywords: "face | grimace | grimacing face",
  category: "Smileys & Emotion"
}, {
  codes: "1F925",
  "char": "🤥",
  name: "lying face",
  keywords: "face | lie | lying face | pinocchio",
  category: "Smileys & Emotion"
}, {
  codes: "1F60C",
  "char": "😌",
  name: "relieved face",
  keywords: "face | relieved",
  category: "Smileys & Emotion"
}, {
  codes: "1F614",
  "char": "😔",
  name: "pensive face",
  keywords: "dejected | face | pensive",
  category: "Smileys & Emotion"
}, {
  codes: "1F62A",
  "char": "😪",
  name: "sleepy face",
  keywords: "face | sleep | sleepy face",
  category: "Smileys & Emotion"
}, {
  codes: "1F924",
  "char": "🤤",
  name: "drooling face",
  keywords: "drooling | face",
  category: "Smileys & Emotion"
}, {
  codes: "1F634",
  "char": "😴",
  name: "sleeping face",
  keywords: "face | sleep | sleeping face | zzz",
  category: "Smileys & Emotion"
}, {
  codes: "1F637",
  "char": "😷",
  name: "face with medical mask",
  keywords: "cold | doctor | face | face with medical mask | mask | sick",
  category: "Smileys & Emotion"
}, {
  codes: "1F915",
  "char": "🤕",
  name: "face with head-bandage",
  keywords: "bandage | face | face with head-bandage | hurt | injury",
  category: "Smileys & Emotion"
}, {
  codes: "1F922",
  "char": "🤢",
  name: "nauseated face",
  keywords: "face | nauseated | vomit",
  category: "Smileys & Emotion"
}, {
  codes: "1F927",
  "char": "🤧",
  name: "sneezing face",
  keywords: "face | gesundheit | sneeze | sneezing face",
  category: "Smileys & Emotion"
}, {
  codes: "1F635",
  "char": "😵",
  name: "dizzy face",
  keywords: "dizzy | face",
  category: "Smileys & Emotion"
}, {
  codes: "1F60E",
  "char": "😎",
  name: "smiling face with sunglasses",
  keywords: "bright | cool | face | smiling face with sunglasses | sun | sunglasses",
  category: "Smileys & Emotion"
}, {
  codes: "1F615",
  "char": "😕",
  name: "confused face",
  keywords: "confused | face | meh",
  category: "Smileys & Emotion"
}, {
  codes: "1F61F",
  "char": "😟",
  name: "worried face",
  keywords: "face | worried",
  category: "Smileys & Emotion"
}, {
  codes: "1F62E",
  "char": "😮",
  name: "face with open mouth",
  keywords: "face | face with open mouth | mouth | open | sympathy",
  category: "Smileys & Emotion"
}, {
  codes: "1F62F",
  "char": "😯",
  name: "hushed face",
  keywords: "face | hushed | stunned | surprised",
  category: "Smileys & Emotion"
}, {
  codes: "1F633",
  "char": "😳",
  name: "flushed face",
  keywords: "dazed | face | flushed",
  category: "Smileys & Emotion"
}, {
  codes: "1F626",
  "char": "😦",
  name: "frowning face with open mouth",
  keywords: "face | frown | frowning face with open mouth | mouth | open",
  category: "Smileys & Emotion"
}, {
  codes: "1F627",
  "char": "😧",
  name: "anguished face",
  keywords: "anguished | face",
  category: "Smileys & Emotion"
}, {
  codes: "1F630",
  "char": "😰",
  name: "anxious face with sweat",
  keywords: "anxious face with sweat | blue | cold | face | rushed | sweat",
  category: "Smileys & Emotion"
}, {
  codes: "1F625",
  "char": "😥",
  name: "sad but relieved face",
  keywords: "disappointed | face | relieved | sad but relieved face | whew",
  category: "Smileys & Emotion"
}, {
  codes: "1F622",
  "char": "😢",
  name: "crying face",
  keywords: "cry | crying face | face | sad | tear",
  category: "Smileys & Emotion"
}, {
  codes: "1F62D",
  "char": "😭",
  name: "loudly crying face",
  keywords: "cry | face | loudly crying face | sad | sob | tear",
  category: "Smileys & Emotion"
}, {
  codes: "1F631",
  "char": "😱",
  name: "face screaming in fear",
  keywords: "face | face screaming in fear | fear | munch | scared | scream",
  category: "Smileys & Emotion"
}, {
  codes: "1F623",
  "char": "😣",
  name: "persevering face",
  keywords: "face | persevere | persevering face",
  category: "Smileys & Emotion"
}, {
  codes: "1F613",
  "char": "😓",
  name: "downcast face with sweat",
  keywords: "cold | downcast face with sweat | face | sweat",
  category: "Smileys & Emotion"
}, {
  codes: "1F62B",
  "char": "😫",
  name: "tired face",
  keywords: "face | tired",
  category: "Smileys & Emotion"
}, {
  codes: "1F624",
  "char": "😤",
  name: "face with steam from nose",
  keywords: "face | face with steam from nose | triumph | won",
  category: "Smileys & Emotion"
}, {
  codes: "1F621",
  "char": "😡",
  name: "pouting face",
  keywords: "angry | face | mad | pouting | rage | red",
  category: "Smileys & Emotion"
}, {
  codes: "1F608",
  "char": "😈",
  name: "smiling face with horns",
  keywords: "face | fairy tale | fantasy | horns | smile | smiling face with horns",
  category: "Smileys & Emotion" // {
  //   codes: "1F4A9",
  //   char: "💩",
  //   name: "pile of poo",
  //   keywords: "dung | face | monster | pile of poo | poo | poop",
  //   category: "Smileys & Emotion"
  // }

}]; // emoji count 64

exports["default"] = _default;