"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _TableModule = _interopRequireDefault(require("./Table.module.scss"));

var _DropdownMenu = _interopRequireDefault(require("../Dropdown/DropdownMenu"));

var _DropdownItem = _interopRequireDefault(require("../Dropdown/DropdownItem"));

var _Dropdown = _interopRequireDefault(require("../Dropdown/Dropdown"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

function TableColumnMenu(_ref) {
  var containerHeight = _ref.containerHeight,
      items = _ref.items,
      onItemClick = _ref.onItemClick,
      isEmpty = _ref.isEmpty;
  var gutterTop = 20;

  var _useState = (0, _react.useState)("left"),
      _useState2 = _slicedToArray(_useState, 2),
      direction = _useState2[0],
      setDirection = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isOpen = _useState4[0],
      setIsOpen = _useState4[1];

  var menuRef = (0, _react.useRef)(null);

  var toggleIcon = _react["default"].createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "2",
    height: "14",
    viewBox: "0 0 2 14"
  }, _react["default"].createElement("path", {
    fill: isOpen ? "#000" : "#7d7e80",
    d: "M2471,497a1,1,0,1,1-1,1A1,1,0,0,1,2471,497Zm0-12a1,1,0,1,1-1,1A1,1,0,0,1,2471,485Zm0,6a1,1,0,1,1-1,1A1,1,0,0,1,2471,491Z",
    transform: "translate(-2470 -485)"
  }));

  var classes = (0, _classnames["default"])(_TableModule["default"].tableColumn, _TableModule["default"].tableRowMenu, isOpen && _TableModule["default"].active);
  var toggle = (0, _react.useCallback)(function () {
    setIsOpen(!isOpen);
  }, [isOpen]);

  var stopLink = function stopLink(e) {
    var menuHeight = menuRef.current.getBoundingClientRect().height;
    var menuOffsetTop = e.target.getBoundingClientRect().top;

    if (containerHeight - gutterTop < menuOffsetTop + menuHeight) {
      setDirection("leftTop");
      toggle();
    } else {
      setDirection("left");
      toggle();
    }

    e.preventDefault();
  };

  if (isEmpty || !items || items.length === 0) {
    return _react["default"].createElement("div", {
      className: classes
    });
  }

  return _react["default"].createElement("div", {
    className: classes,
    onClick: stopLink
  }, _react["default"].createElement("span", {
    className: _TableModule["default"].tableRowMenuIcon
  }, toggleIcon), _react["default"].createElement("div", {
    className: _TableModule["default"].tableRowMenuContent
  }, _react["default"].createElement(_Dropdown["default"], {
    isOpen: isOpen,
    toggle: toggle
  }, _react["default"].createElement(_DropdownMenu["default"], {
    innerRef: menuRef,
    gutterTop: gutterTop,
    gutterRight: -20,
    direction: direction
  }, items.map(function (item) {
    return _react["default"].createElement(_DropdownItem["default"], {
      key: item.key,
      onClick: function onClick() {
        setIsOpen(false);
        onItemClick(item.key);
      }
    }, item.label);
  })))));
}

TableColumnMenu.propTypes = {
  children: _propTypes["default"].node
};
var _default = TableColumnMenu;
exports["default"] = _default;