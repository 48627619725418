"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _classnames = _interopRequireDefault(require("classnames"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _DropdownModule = _interopRequireDefault(require("./Dropdown.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};

  if (obj != null) {
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function DropdownMenu(_ref) {
  var children = _ref.children,
      direction = _ref.direction,
      fullHeight = _ref.fullHeight,
      gutterTop = _ref.gutterTop,
      gutterBottom = _ref.gutterBottom,
      innerRef = _ref.innerRef,
      gutterRight = _ref.gutterRight;
  var inputRef = (0, _react.useState)(null);
  var classes = (0, _classnames["default"])(_DropdownModule["default"].selectDropdown, _DropdownModule["default"][direction], fullHeight && _DropdownModule["default"].fullHeight);
  var gutterStyles = {
    marginTop: gutterTop,
    marginBottom: gutterBottom,
    left: gutterRight && -185 + -gutterRight
  };
  var handleRef = (0, _react.useCallback)(function (node) {
    if (innerRef) {
      innerRef.current = node;
    }

    inputRef.current = node;
  }, [inputRef, innerRef]);
  return _react["default"].createElement("div", {
    style: gutterStyles,
    ref: handleRef,
    className: classes
  }, _react["default"].createElement("ul", null, children));
}

DropdownMenu.propTypes = {
  direction: _propTypes["default"].string,
  fullHeight: _propTypes["default"].bool
};
var _default = DropdownMenu;
exports["default"] = _default;