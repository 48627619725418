import React from "react";
import { translation_default } from "helpers/translations";
import { connect } from "react-redux";

function WidgetWelcomeContainer({
  teamMessage,
  helloMessage,
  defaultLanguage
}) {
  const lang = defaultLanguage || "en";
  let printTeamMessage = teamMessage || translation_default.teamMessage[lang];
  let printHelloMessage =
    helloMessage || translation_default.helloMessage[lang];

  return (
    <div className="channels-wrapper-chat-message-container">
      <div className="channels-wrapper-chat-hello">{printHelloMessage}</div>
      <div className="channels-wrapper-chat-message">{printTeamMessage}</div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    defaultLanguage: state.setup.current.introduce.defaultLanguage,
    teamMessage: state.setup.current.welcome.teamMessage,
    helloMessage: state.setup.current.welcome.helloMessage
  };
};

export default connect(mapStateToProps)(WidgetWelcomeContainer);
