"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _GridModule = _interopRequireDefault(require("./Grid.module.scss"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function Col(_ref) {
  var children = _ref.children,
      sm = _ref.sm,
      md = _ref.md,
      lg = _ref.lg,
      xl = _ref.xl,
      justified = _ref.justified,
      gutterLeft = _ref.gutterLeft,
      gutterRight = _ref.gutterRight,
      gutterTop = _ref.gutterTop,
      gutterBottom = _ref.gutterBottom,
      column = _ref.column,
      auto = _ref.auto,
      align = _ref.align,
      verticalAlign = _ref.verticalAlign;
  var classes = (0, _classnames["default"])(_GridModule["default"].col, sm && _GridModule["default"]["colSm".concat(sm)], md && _GridModule["default"]["colMd".concat(md)], lg && _GridModule["default"]["colLg".concat(lg)], xl && _GridModule["default"]["colXl".concat(xl)], justified && _GridModule["default"].colJustified, auto && _GridModule["default"].colAuto, column && _GridModule["default"].columnDirection, verticalAlign && _GridModule["default"]["align-" + verticalAlign], align && _GridModule["default"]["justify-" + align]);
  var inlineStyle = {
    paddingLeft: gutterLeft,
    paddingRight: gutterRight,
    paddingTop: gutterTop,
    paddingBottom: gutterBottom
  };
  return _react["default"].createElement("div", {
    className: classes,
    style: inlineStyle
  }, children);
}

Col.propTypes = {
  children: _propTypes["default"].node,
  sm: _propTypes["default"].number,
  md: _propTypes["default"].number,
  lg: _propTypes["default"].number,
  gutterLeft: _propTypes["default"].number,
  gutterRight: _propTypes["default"].number,
  paddingTop: _propTypes["default"].number,
  paddingBottom: _propTypes["default"].number,
  justified: _propTypes["default"].bool,
  auto: _propTypes["default"].bool
};
var _default = Col;
exports["default"] = _default;